import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

type Select1Props = {
  selectData: {
    value: string;
    displayValue: string;
  }[];
  value: string;
  onChange: (selectedValue: string) => void;
  label?: string;
};

/**
 *
 * @selectData {value: 선택한 값으로 관리될 값, displayValue: 선택하는 ui에서 보일 }[];
 * @value 선택된 value 넣어주면 됨
 * @onChnage (selectedValue: string) => void; 선택한 값이 인자로 전달됨
 * @label [optional] 셀렉트 위에 표시될 값 넣어주기
 * @returns
 */
const Select1 = ({ selectData = [], onChange, value, label }: Select1Props) => {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl sx={{ width: "150px" }} size="small">
        {label && (
          <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        )}
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={label ? label : ""}
          onChange={(e) => onChange(e.target.value)}
        >
          {selectData.length > 0 &&
            selectData.map((data, idx) => (
              <MenuItem key={idx} value={data.value}>
                {data.displayValue}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default Select1;
