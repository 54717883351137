import { PropsWithChildren } from "react";
import styled from "@emotion/styled";

interface RegisterRowWrapperProps extends PropsWithChildren {
  title: string;
}

const RegisterRowWrapper = ({ title, children }: RegisterRowWrapperProps) => {
  return (
    <Container>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
      <ContentContainer>{children}</ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  width: 150px;
  height: 40px;
  margin-right: 10px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const ContentContainer = styled.div`
  width: 800px;
`;

export default RegisterRowWrapper;
