import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { getCryptoLiveInfo, patchCryptoLiveUpdate } from "@/api/cryptoApi";

import LayoutContainer from "@/components/LayoutContainer";
import Select1 from "@/components/Select1";
import RegisterRowWrapper from "@/components/table/RegisterRowWrapper";
import ImgUpload from "@/components/ImgUpload";
import Title1 from "@/components/Title1";

import { useLocation, useNavigate } from "react-router-dom";
import { Button, TextField, Typography } from "@mui/material";

import {
  BATTIME_SELECT_DATA,
  REWARD_SELECT_DATA,
  USECOIN_SELECT_DATA,
  USERTYPE_SELECT_DATA,
} from "@/pages/crypto/cryptoConst";
import { convertUTCDateToLocalDate, makeImgServerUrl } from "@/utils/format";
import {
  postImgBannerDelete,
  postImgBannerUpdate,
  postImgSymbolUpdate,
  postImgBannerAdd,
  postImgSymbolAdd,
} from "@/api/api";

const CryptoEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = location.state;

  const [contentKrName, setContentKrName] = useState("");
  const [contentEnName, setContentEnName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userType, setUserType] = useState("all");
  const [rewardType, setRewardType] = useState("handy");
  const [carryOver, setCarryOver] = useState(150); // 보상 배수
  const [adsLimit, setAdsLimit] = useState(10);
  const [adsUsdp, setAdsUsdp] = useState(1000);
  const [useCoin, setUseCoin] = useState("BTCUSDT");
  const [batTime, setBatTime] = useState(60);
  const [bannerUrl, setBannerUrl] = useState<any>();
  const [bannerForm, setBannerForm] = useState<any>();
  const [symbolUrl, setSymbolUrl] = useState<any>();
  const [symbolForm, setSymbolForm] = useState<any>();

  const [bannerOldUrl, setBannerOldUrl] = useState("");
  const [symbolOldUrl, setSymbolOldUrl] = useState("");

  useEffect(() => {
    requestCryptoLiveInfo();
  }, []);

  const requestCryptoLiveInfo = async () => {
    try {
      const res = await getCryptoLiveInfo({ crypto_info_id: id });

      if (res.status === 200) {
        const {
          content_name,
          content_e_name,
          start_date,
          end_date,
          participants,
          reward_type,
          carry_over_cnt,
          ads_cnt,
          ads_usdp,
          symbol,
          bat_time,
          img,
          symbol_img,
        } = res.data[0];

        setContentKrName(content_name);
        setContentEnName(content_e_name);
        setStartDate(convertUTCDateToLocalDate(start_date).toISOString().substring(0, 16));
        setEndDate(convertUTCDateToLocalDate(end_date).toISOString().substring(0, 16));
        setUserType(participants === 2 ? "nft" : "all");
        setRewardType(reward_type);
        setCarryOver(carry_over_cnt * 100);
        setAdsLimit(ads_cnt);
        setAdsUsdp(ads_usdp);
        setUseCoin(symbol);
        setBatTime(bat_time);
        if (img) {
          setBannerUrl(makeImgServerUrl(img));
          setBannerOldUrl(img);
        }

        if (symbol_img) {
          setSymbolUrl(makeImgServerUrl(symbol_img));
          setSymbolOldUrl(symbol_img);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const requestCryptoLiveUpdate = async () => {
    if (!contentKrName) return alert("콘텐츠 국문 이름을 입력하세요");
    if (!contentEnName) return alert("콘텐츠 영문 이름을 입력하세요");
    if (!symbolUrl) return alert("심볼 이미지를 등록하세요");
    if (!startDate) return alert("콘텐츠 시작 일시를 입력하세요");
    if (!endDate) return alert("콘텐츠 종료 일시를 입력하세요");
    if (!carryOver) return alert("보상배율을 설정해주세요");
    if (!adsLimit) return alert("광고 시청 횟수를 설정해주세요");
    if (!adsUsdp) return alert("광고 보상 USDP를 설정해주세요");
    if (!adsUsdp) return alert("광고 보상 USDP를 설정해주세요");

    try {
      let bannerImgUrl: any = bannerOldUrl;
      if (bannerForm) {
        if (bannerImgUrl) {
          const bannerImgRes = await postImgBannerUpdate(bannerForm);
          bannerImgUrl = bannerImgRes.data.img;
        } else {
          const bannerImgRes = await postImgBannerAdd(bannerForm);
          bannerImgUrl = bannerImgRes.data.img;
        }
      } else {
        if (bannerUrl === null) {
          if (bannerOldUrl) {
            await postImgBannerDelete({
              img_path: bannerOldUrl,
            });
          }
          bannerImgUrl = null;
        }
      }

      let symbolImgUrl: any = symbolOldUrl;

      if (symbolImgUrl) {
        const symbolImgRes = await postImgSymbolUpdate(symbolForm);
        symbolImgUrl = symbolImgRes.data.symbol_img;
      } else {
        const symbolImgRes = await postImgSymbolAdd(symbolForm);
        symbolImgUrl = symbolImgRes.data.symbol_img;
      }

      const res = await patchCryptoLiveUpdate({
        crypto_info_id: id,
        content_name: contentKrName,
        content_e_name: contentEnName,
        img: bannerImgUrl,
        symbol_img: symbolImgUrl,
        start_date: startDate,
        end_date: endDate,
        participants: userType === "nft" ? 2 : 1,
        reward_type: rewardType,
        carry_over_cnt: carryOver,
        ads_cnt: adsLimit,
        ads_usdp: adsUsdp,
        symbol: useCoin,
        bat_time: batTime,
      });

      if (res.status === 200) {
        navigate("/cryptolive/list");
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <LayoutContainer>
      <Title1 text="콘텐츠 수정" />
      <Container>
        <RegisterRowWrapper title="콘텐츠 국문 이름">
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="콘텐츠 국문 이름을 입력해주세요"
            value={contentKrName}
            onChange={(e) => setContentKrName(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="콘텐츠 영문 이름">
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="콘텐츠 영문 이름을 입력해주세요"
            value={contentEnName}
            onChange={(e) => setContentEnName(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="배너 이미지">
          <ImgUpload
            url={bannerUrl}
            setUrl={setBannerUrl}
            setForm={setBannerForm}
            width={160}
            height={100}
            type="edit"
            name="banner_image"
            editUrl={bannerOldUrl}
          />
          <TextContainer>
            <Typography>🔥 8:5 비율의 이미지를 업로드 해주세요</Typography>
            <Typography>🔥 권장 해상도는 960 × 600 입니다</Typography>
          </TextContainer>
        </RegisterRowWrapper>

        <RegisterRowWrapper title="코인 이미지">
          <ImgUpload
            url={symbolUrl}
            setUrl={setSymbolUrl}
            setForm={setSymbolForm}
            width={100}
            height={100}
            type="edit"
            name="symbol_image"
            editUrl={symbolOldUrl}
          />
          <TextContainer>
            <Typography>🔥 1:1 비율의 이미지를 업로드 해주세요</Typography>
            <Typography>🔥 권장 해상도는 60 × 60 입니다</Typography>
          </TextContainer>
        </RegisterRowWrapper>

        <RegisterRowWrapper title="시작일시">
          <TextField
            id="datetime-local"
            type="datetime-local"
            sx={{ width: 250 }}
            InputLabelProps={{
              shrink: true,
            }}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="종료일시">
          <TextField
            id="datetime-local"
            type="datetime-local"
            sx={{ width: 250 }}
            InputLabelProps={{
              shrink: true,
            }}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="참여대상">
          <Select1
            selectData={USERTYPE_SELECT_DATA}
            value={userType}
            onChange={(selectData) => setUserType(selectData)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="보상 종류">
          <Select1
            selectData={REWARD_SELECT_DATA}
            value={rewardType}
            onChange={(selectData) => setRewardType(selectData)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="보상 배율">
          <AlignContainer>
            <TextField
              variant="outlined"
              sx={{ width: "400px" }}
              size="small"
              placeholder="보상 배율을 입력해주세요"
              helperText="* 정답 시 획득 보상 배율 설정 (베팅 USDP x 보상 배율로 지급)"
              value={carryOver}
              onChange={(e) => {
                if (!Number(e.target.value)) return;
                setCarryOver(Number(e.target.value));
              }}
            />
            <Typography sx={{ height: "40px", display: "flex", alignItems: "center" }}>%</Typography>
          </AlignContainer>
        </RegisterRowWrapper>

        <RegisterRowWrapper title="광고 시청 횟수">
          <TextField
            variant="outlined"
            sx={{ width: "400px" }}
            size="small"
            placeholder="광고 시청 횟수를 입력하세요"
            helperText="* 광고 시청 제한 횟수"
            value={adsLimit}
            onChange={(e) => {
              if (!Number(e.target.value)) return;
              setAdsLimit(Number(e.target.value));
            }}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="광고 보상 USDP">
          <TextField
            variant="outlined"
            sx={{ width: "400px" }}
            size="small"
            placeholder="광고 시청 횟수를 입력하세요"
            helperText="* 광고 시청 시 획득 USDP"
            value={adsUsdp}
            onChange={(e) => {
              if (!Number(e.target.value)) return;
              setAdsUsdp(Number(e.target.value));
            }}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="사용 코인">
          <Select1 selectData={USECOIN_SELECT_DATA} value={useCoin} onChange={(selectData) => setUseCoin(selectData)} />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="베팅 시간">
          <Select1
            selectData={BATTIME_SELECT_DATA}
            value={batTime.toString()}
            onChange={(selectData) => setBatTime(Number(selectData))}
          />
        </RegisterRowWrapper>

        <BtnContainer>
          <Button variant="contained" sx={{ width: "150px" }} onClick={requestCryptoLiveUpdate}>
            수정
          </Button>
          <Button variant="outlined" sx={{ width: "150px" }} onClick={() => navigate("/cryptolive/list")}>
            취소
          </Button>
        </BtnContainer>
      </Container>
    </LayoutContainer>
  );
};

const Container = styled.div`
  margin-top: 20px;
  padding-bottom: 100px;
`;

const AlignContainer = styled.div`
  display: flex;
  gap: 5px;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
`;

const TextContainer = styled.div`
  margin-top: 10px;
`;

export default CryptoEdit;
