import { useState } from "react";
import styled from "@emotion/styled";

import { postManagementNoticeAdd } from "@/api/managementApi";

import RegisterRowWrapper from "@/components/table/RegisterRowWrapper";
import LayoutContainer from "@/components/LayoutContainer";
import ManagementTabBox from "@/pages/management/components/ManagementTabBox";

import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Divider, TextField, Typography } from "@mui/material";

import { numbers } from "@/utils/format";

const NoticeRegister = () => {
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [push, setPush] = useState(false);
  const [pushSend, setPushSend] = useState(false);
  const [pushTitle, setPushTitle] = useState("");
  const [pushContent, setPushContent] = useState("");
  const [status, setStatus] = useState(false);
  const [sortNum, setSortNum] = useState(0);
  const [krTitle, setKrTitle] = useState("");
  const [krContent, setKrContent] = useState("");
  const [enTitle, setEnTitle] = useState("");
  const [enContent, setEnContent] = useState("");

  const requestManagementNoticeAdd = async () => {
    try {
      const res = await postManagementNoticeAdd({
        title,
        start_date: startDate,
        end_date: endDate,
        push,
        push_send: pushSend,
        push_send_title: pushTitle,
        push_send_content: pushContent,
        status,
        sort_num: sortNum,
        k_title: krTitle,
        k_content: krContent,
        e_title: enTitle,
        e_content: enContent,
      });

      if (res.status === 200) {
        navigate("/management/notice");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <LayoutContainer>
      <ManagementTabBox />
      <Container>
        <RegisterRowWrapper title="제목">
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="관리자페이지에서 표시되는 제목입니다"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="시작일시">
          <TextField
            id="datetime-local"
            type="datetime-local"
            size="small"
            sx={{ width: 250 }}
            InputLabelProps={{
              shrink: true,
            }}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="종료일시">
          <TextField
            id="datetime-local"
            type="datetime-local"
            size="small"
            sx={{ width: 250 }}
            InputLabelProps={{
              shrink: true,
            }}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="푸시 알림 여부">
          <CheckboxContainer>
            <Checkbox checked={push} onChange={(e, checked) => setPush(checked)} />
            <Typography sx={{ marginLeft: "10px", marginRight: "50px" }}>푸시 알림</Typography>
            <Checkbox checked={pushSend} onChange={(e, checked) => setPushSend(checked)} />
            <Typography sx={{ margin: "0 10px" }}>알림 보내기</Typography>
            <Typography sx={{ color: "rgb(250,50,50)" }}>❗️체크할 경우 등록/수정시 알림이 전송됩니다</Typography>
          </CheckboxContainer>
        </RegisterRowWrapper>

        <RegisterRowWrapper title="알림 제목">
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="알림 제목을 입력하세요"
            value={pushTitle}
            onChange={(e) => setPushTitle(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="알림 내용">
          <TextField
            id="outlined-multiline-static"
            multiline
            fullWidth
            rows={4}
            placeholder="알림 내용을 입력하세요"
            size="small"
            value={pushContent}
            onChange={(e) => setPushContent(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="게시여부">
          <CheckboxContainer>
            <Checkbox checked={status} onChange={(e, checked) => setStatus(checked)} />
            <Typography sx={{ marginLeft: "10px" }}>게시</Typography>
          </CheckboxContainer>
        </RegisterRowWrapper>

        <RegisterRowWrapper title="게시순서">
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="게시 순서를 입력하세요"
            value={sortNum}
            onChange={(e) => {
              if (e.target.value && !Number(e.target.value)) return;
              setSortNum(Number(e.target.value));
            }}
          />
        </RegisterRowWrapper>

        <Divider sx={{ marginBottom: "20px" }} />

        <RegisterRowWrapper title="국문 제목">
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="국문 제목을 입력하세요"
            value={krTitle}
            onChange={(e) => setKrTitle(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="국문 내용">
          <TextField
            id="outlined-multiline-static"
            multiline
            fullWidth
            rows={4}
            placeholder="국문 내용을 입력하세요"
            size="small"
            value={krContent}
            onChange={(e) => setKrContent(e.target.value)}
          />
          <TextContainer>
            <Typography>
              🔥 링크는 <AccentText>/#</AccentText> 과 <AccentText>#/</AccentText> 로 감싸주세요
            </Typography>
            <Typography>
              🔥 예시 <AccentText>/#</AccentText>https://handypick.io/<AccentText>#/</AccentText>
            </Typography>
            <Typography>🔥 줄바꿈은 입력한 텍스트 그대로 노출됩니다</Typography>
          </TextContainer>
        </RegisterRowWrapper>

        <RegisterRowWrapper title="영문 제목">
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="영문 제목을 입력하세요"
            value={enTitle}
            onChange={(e) => setEnTitle(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="영문 내용">
          <TextField
            id="outlined-multiline-static"
            fullWidth
            multiline
            rows={4}
            placeholder="영문 내용을 입력하세요"
            size="small"
            value={enContent}
            onChange={(e) => setEnContent(e.target.value)}
          />
          <TextContainer>
            <Typography>
              🔥 링크는 <AccentText>/#</AccentText> 과 <AccentText>#/</AccentText> 로 감싸주세요
            </Typography>
            <Typography>
              🔥 예시 <AccentText>/#</AccentText>https://handypick.io/<AccentText>#/</AccentText>
            </Typography>
            <Typography>🔥 줄바꿈은 입력한 텍스트 그대로 노출됩니다</Typography>
          </TextContainer>
        </RegisterRowWrapper>

        <BtnContainer>
          <Button variant="contained" sx={{ width: "150px" }} onClick={requestManagementNoticeAdd}>
            저장
          </Button>
          <Button variant="outlined" sx={{ width: "150px" }} onClick={() => navigate("/management/notice")}>
            취소
          </Button>
        </BtnContainer>
      </Container>
    </LayoutContainer>
  );
};

const Container = styled.div`
  margin-bottom: 100px;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

const TextContainer = styled.div`
  margin-top: 10px;
`;

const AccentText = styled.span`
  font-weight: bold;
  color: red;
`;

export default NoticeRegister;
