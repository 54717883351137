import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

type Table2Props = {
  head: string[];
  rows: (string | number | JSX.Element)[][];
  rowColor?: ("success" | "error" | "primary" | "secondary" | "warning" | "normal" | "cancel")[];
};

/**
 * 데이터 형식
 *   head:[title1, title2, ....],
 *   rows:[
 *     [data1, data2, ...],
 *     [data1, data2, ...],
 *     ...
 *   ],
 */
const Table2 = ({ head, rows, rowColor }: Table2Props) => {
  const ROW_COLOR = {
    success: "#e8f5e9",
    error: "#ffebee",
    primary: "#e3f2fd",
    secondary: "#f3e5f5",
    warning: "#fffde7",
    normal: "#fafafa",
    cancel: "#fff3e0",
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {head.map((head, idx) => (
              <TableCell key={idx} align="center">
                {head}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowColor
            ? rows.map((row, idx) => (
                <TableRow
                  key={idx}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 }, background: ROW_COLOR[rowColor[idx]] }}
                >
                  {row.map((text, idx) => (
                    <TableCell key={idx} align="center">
                      {text}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            : rows.map((row, idx) => (
                <TableRow key={idx} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  {row.map((text, idx) => (
                    <TableCell key={idx} align="center">
                      {text}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Table2;
