import { apost } from "@/configs/AxiosConfig";

type UserList = {
  page: number;
};
/**
 * 회원 정보 목록 조회 api
 * @param body
 * ```
 * {
 *   page: number;
 * }
 * ```
 * @returns
 */
export const getUserList = (body: UserList) => {
  return apost("/user/list", body);
};

type UserLogUsdp = {
  user_info_id: number;
  email: string;
  page: number;
};
/**
 * 회원 usdp 로그 및 usdp 조회 api
 * @param body
 * ```
 * {
 *   user_info_id: number;
 *   email: string;
 *   page: number;
 * }
 * ```
 * @returns
 */
export const getUserLogUsdp = (body: UserLogUsdp) => {
  return apost("/user/log/usdp", body);
};

type UserLogPoint = {
  user_info_id: number;
  email: string;
  page: number;
};
/**
 * 버전 1의 포인트랑 핸디 포인트 목록 및 수치 조회 api (버전1 포인트는 2023-11-23 이후에 업어질 예정)
 * @param body
 * ```
 * {
 *   user_info_id: number;
 *   email: string;
 *   page: number;
 * }
 * ```
 * @returns
 */
export const getUserLogPoint = (body: UserLogPoint) => {
  return apost("/user/log/point", body);
};

type UserLogHighLow = {
  user_info_id: number;
  email: string;
  page: number;
};
/**
 * highlow 로그 조회 api
 * @param body
 * ```
 * {
 *   user_info_id: number;
 *   email: string;
 *   page: number;
 * }
 * ```
 * @returns
 */
export const postUserLogHighLow = (body: UserLogHighLow) => {
  return apost("/user/log/highlow", body);
};

type UserInfo = {
  user_info_id: number;
};
/**
 * 회원 정보 수정을 위해 데이터를 받아오는 회원 정보 상세 조회 api
 * @param body
 * ```
 * {
 *   user_info_id: number;
 * }
 * ```
 * @returns
 */
export const getUserInfo = (body: UserInfo) => {
  return apost("/user/info", body);
};

type UserAdd = {
  email: string;
  img_path: string;
  name: string;
  password: string;
  gender: string;
  handy: number;
  country: string;
  lang: string;
};
/**
 * 회원 등록 api
 * @param body
 * ```
 * {
 *   email: string;
 *   img_path: string;
 *   name: string;
 *   password: string;
 *   gender: string;
 *   handy: number;
 *   country: string;
 *   lang: string;
 * }
 * ```
 * @returns
 */
export const postUserAdd = (body: UserAdd) => {
  return apost("/user/add", body);
};

type UserDelete = {
  user_info_id: number;
  email: string;
};
/**
 * 계정 삭제 api
 * @param body
 * ```
 * {
 *   user_info_id: number;
 *   email: string;
 * }
 * ```
 * @returns
 */
export const deleteUserDelete = (body: UserDelete) => {
  return apost("/user/delete", body);
};

type UserUpdate = {
  user_info_id: number;
  email: string;
  img_path: string;
  name: string;
  handy: number;
  lang: string;
  gender: string;
  everlasting: boolean;
  content: string;
};
export const postUserUpdate = (body: UserUpdate) => {
  return apost("/user/update", body);
};

type UserSearch = {
  category: string;
  keyword: string;
  page: number;
};
/**
 * 회원 검색 api
 * @param body
 * ```
 * {
 *   category: string;
 *   keyword: string;
 *   page: number;
 * }
 * ```
 * @returns
 */
export const postUserSearch = (body: UserSearch) => {
  return apost("/user/search", body);
};

export const postUserImgProfileAdd = <T>(body: T) => {
  return apost("/user/img/profile/add", body);
};
