import React, { useState } from "react";
import styled from "@emotion/styled";

import { postManagementWithdrawUpdateMemo } from "@/api/managementApi";

import { Button, TextField } from "@mui/material";

type EditMemoProps = {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  idx: number;
  editMemo: string;
  version: number;
  requestManagementWithdraw: () => void;
};

const EditMemo = ({ setModal, idx, editMemo, version, requestManagementWithdraw }: EditMemoProps) => {
  const [memo, setMemo] = useState(editMemo);

  const requestManagementWithdrawUpdateMemo = async () => {
    try {
      const res = await postManagementWithdrawUpdateMemo({
        version,
        idx,
        memo,
      });

      if (res.status === 200) {
        setModal(false);
        requestManagementWithdraw();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container>
      <TextField
        id="outlined-multiline-static"
        multiline
        sx={{ width: "500px" }}
        rows={4}
        placeholder="메모를 입력하세요"
        value={memo}
        onChange={(e) => setMemo(e.target.value)}
      />
      <BtnContainer>
        <Button
          variant={"contained"}
          sx={{ width: "150px" }}
          color="primary"
          onClick={requestManagementWithdrawUpdateMemo}
        >
          확인
        </Button>
        <Button variant={"contained"} sx={{ width: "150px" }} color="inherit" onClick={() => setModal(false)}>
          닫기
        </Button>
      </BtnContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 550px;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
`;

export default EditMemo;
