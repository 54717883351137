import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { postCryptoLiveCommentList } from "@/api/cryptoApi";

import LayoutContainer from "@/components/LayoutContainer";
import Table1 from "@/components/table/Table1";
import CryptoTabs from "@/pages/crypto/components/CryptoTabs";

import { Button, Skeleton, TextField } from "@mui/material";

import { convertUTCDateToLocalDate, convertYYYYMMDD_HHMMSS } from "@/utils/format";
import { COMMENT_TABLE_HEAD } from "@/pages/management/managementConst";
import { CRYPTO_COMMENT_PAGE_PER_CONTENT_COUNT } from "@/pages/crypto/cryptoConst";
import Pagination from "@/components/Pagination";
import Dialog from "@/components/Dialog";
import BlockCommentModal from "@/pages/crypto/components/BlockCommentModal";
import { useNavigate } from "react-router-dom";

const CryptoCommentList = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [day, setDay] = useState(new Date().toISOString().substring(0, 10));
  const [commentBlockUserId, setCommentBlockUserId] = useState(0);

  useEffect(() => {
    requestCryptoLiveCommentList();
  }, [day, page]);

  const requestCryptoLiveCommentList = async () => {
    try {
      const res = await postCryptoLiveCommentList({ day, page });

      const { list, totalCnt } = res.data;
      setTotalCount(totalCnt);
      if (list.length > 0) {
        setCommentList(list);
      } else {
        alert("데이터가 없습니다.");
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleBlockModal = (commentBlockUserId: number) => {
    setCommentBlockUserId(commentBlockUserId);
    setModal(true);
  };

  return (
    <LayoutContainer>
      <CryptoTabs />

      <TopContainer>
        <SearchContainer>
          <TextField
            id="date"
            type="date"
            sx={{ minWidth: "170px" }}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            value={day}
            onChange={(e) => setDay(e.target.value)}
          />
        </SearchContainer>
      </TopContainer>

      {loading ? (
        <div>
          {[1, 2, 3, 4, 5].map((_, idx) => (
            <Skeleton key={idx} variant="rectangular" height={60} sx={{ marginBottom: "2px" }} />
          ))}
        </div>
      ) : (
        <div>
          <Dialog open={modal} onClose={() => setModal(false)} title="댓글 차단">
            <BlockCommentModal
              setModal={setModal}
              userInfoId={commentBlockUserId}
              requestCryptoLiveCommentList={requestCryptoLiveCommentList}
            />
          </Dialog>
          {commentList?.length > 0 && (
            <Table1
              head={COMMENT_TABLE_HEAD}
              rows={commentList.map((data) => {
                const { user_info_id, email, comment, time } = data;
                return [
                  user_info_id,
                  email ? email : "NULL",
                  comment ? comment : "NULL",
                  convertYYYYMMDD_HHMMSS(convertUTCDateToLocalDate(new Date(time))),
                  <Button
                    variant={"contained"}
                    // color="error"
                    size="small"
                    onClick={() =>
                      navigate(`/account/edit`, {
                        state: {
                          id: user_info_id,
                        },
                      })
                    }
                  >
                    차단 정보 수정
                  </Button>,
                ];
              })}
            />
          )}

          <Pagination
            page={page}
            totalPage={Math.ceil(totalCount / CRYPTO_COMMENT_PAGE_PER_CONTENT_COUNT)}
            selectPage={(page) => setPage(page)}
          />
        </div>
      )}
    </LayoutContainer>
  );
};

const TopContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 0 15px 0;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default CryptoCommentList;
