import { useState } from "react";
import styled from "@emotion/styled";

import RegisterRowWrapper from "@/components/table/RegisterRowWrapper";
import Select1 from "@/components/Select1";
import LayoutContainer from "@/components/LayoutContainer";
import ManagementTabBox from "@/pages/management/components/ManagementTabBox";
import ImgUpload from "@/components/ImgUpload";

import { useNavigate } from "react-router-dom";
import { Button, Checkbox, TextField, Typography } from "@mui/material";

import { BANNER_PAGE_SELECT_DATA } from "@/pages/management/managementConst";
import { postManagementBannerAdd } from "@/api/managementApi";
import { postImgBannerAdd } from "@/api/api";

const BannerRegister = () => {
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [push, setPush] = useState(false);
  const [status, setStatus] = useState(false);
  const [sortNum, setSortNum] = useState(0);
  const [page, setPage] = useState("Main");

  const [krBannerUrl, setKrBannerUrl] = useState<any>(null);
  const [krBannerForm, setKrBannerForm] = useState<any>(null);

  const [enBannerUrl, setEnBannerUrl] = useState<any>(null);
  const [enBannerForm, setEnBannerForm] = useState<any>(null);

  const requestManagementBannerAdd = async () => {
    if (!title) return alert("제목을 입력하세요");
    if (!startDate) return alert("게시 시작 일시를 입력하세요");
    if (!endDate) return alert("게시 종료 일시를 입력하세요");
    if (!krBannerForm) return alert("국문 배너 이미지를 등록하세요");
    if (!enBannerForm) return alert("영문 배너 이미지를 등록하세요");

    try {
      const krBanner = await postImgBannerAdd(krBannerForm);
      const enBanner = await postImgBannerAdd(enBannerForm);

      const res = await postManagementBannerAdd({
        title,
        start_date: startDate,
        end_date: endDate,
        push,
        status,
        sort_num: sortNum,
        page,
        k_img: krBanner.data.img,
        e_img: enBanner.data.img,
      });

      if (res.status === 200) {
        navigate("/management/banner");
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <LayoutContainer>
      <ManagementTabBox />

      <RegisterRowWrapper title="제목">
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder="관리자페이지에서 표시되는 제목입니다"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </RegisterRowWrapper>

      <RegisterRowWrapper title="시작일시">
        <TextField
          id="datetime-local"
          type="datetime-local"
          size="small"
          sx={{ width: 250 }}
          InputLabelProps={{
            shrink: true,
          }}
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
      </RegisterRowWrapper>

      <RegisterRowWrapper title="종료일시">
        <TextField
          id="datetime-local"
          type="datetime-local"
          size="small"
          sx={{ width: 250 }}
          InputLabelProps={{
            shrink: true,
          }}
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </RegisterRowWrapper>

      <RegisterRowWrapper title="푸시 알림 여부">
        <CheckboxContainer>
          <Checkbox checked={push} onChange={(e, checked) => setPush(checked)} />
          <Typography sx={{ marginLeft: "10px" }}>푸시 알림</Typography>
        </CheckboxContainer>
      </RegisterRowWrapper>

      <RegisterRowWrapper title="게시 여부">
        <CheckboxContainer>
          <Checkbox checked={status} onChange={(e, checked) => setStatus(checked)} />
          <Typography sx={{ marginLeft: "10px" }}>게시</Typography>
        </CheckboxContainer>
      </RegisterRowWrapper>

      <RegisterRowWrapper title="게시순서">
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder="게시 순서를 입력하세요"
          value={sortNum}
          onChange={(e) => {
            if (e.target.value && !Number(e.target.value)) return;
            setSortNum(Number(e.target.value));
          }}
        />
      </RegisterRowWrapper>

      <RegisterRowWrapper title="게시 위치">
        <Select1 selectData={BANNER_PAGE_SELECT_DATA} value={page} onChange={(page) => setPage(page)} />
      </RegisterRowWrapper>

      <RegisterRowWrapper title="국문 배너 이미지">
        <ImgUpload
          url={krBannerUrl}
          setUrl={setKrBannerUrl}
          setForm={setKrBannerForm}
          type="kr"
          name="banner_image"
          width={320}
          height={72}
        />

        <TextContainer>
          <Typography>🔥 40:9 비율의 이미지를 업로드 해주세요</Typography>
          <Typography>🔥 권장 해상도는 960 × 216 입니다</Typography>
        </TextContainer>
      </RegisterRowWrapper>

      <RegisterRowWrapper title="영문 배너 이미지">
        <ImgUpload
          url={enBannerUrl}
          setUrl={setEnBannerUrl}
          setForm={setEnBannerForm}
          type="en"
          name="banner_image"
          width={320}
          height={72}
        />
        <TextContainer>
          <Typography>🔥 40:9 비율의 이미지를 업로드 해주세요</Typography>
          <Typography>🔥 권장 해상도는 960 × 216 입니다</Typography>
        </TextContainer>
      </RegisterRowWrapper>

      <BtnContainer>
        <Button variant="contained" sx={{ width: "150px" }} onClick={requestManagementBannerAdd}>
          저장
        </Button>
        <Button variant="outlined" sx={{ width: "150px" }} onClick={() => navigate("/management/banner")}>
          취소
        </Button>
      </BtnContainer>
    </LayoutContainer>
  );
};

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
`;

const TextContainer = styled.div`
  margin-top: 10px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

export default BannerRegister;
