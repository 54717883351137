import React, { useState } from "react";
import styled from "@emotion/styled";

import { Button, TextField } from "@mui/material";
import { postCryptoLiveCommentBlock } from "@/api/cryptoApi";

type BlockCommentModalProps = {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  userInfoId: number;
  requestCryptoLiveCommentList: () => void;
};

const BlockCommentModal = ({ setModal, userInfoId, requestCryptoLiveCommentList }: BlockCommentModalProps) => {
  const [commentBlockReason, setCommentBlockReason] = useState("");

  const requestCryptoLiveCommentBlock = async () => {
    try {
      const res = await postCryptoLiveCommentBlock({
        user_info_id: userInfoId,
        comment_blocked: 1,
        comment_content: commentBlockReason,
      });

      setModal(false);
      alert(res.message);
      requestCryptoLiveCommentList();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container>
      <TextField
        id="outlined-multiline-static"
        multiline
        sx={{ width: "500px", marginBottom: "20px" }}
        rows={4}
        placeholder="차단 이유를 입력하세요"
        value={commentBlockReason}
        onChange={(e) => setCommentBlockReason(e.target.value)}
      />
      <Text>해당 유저의 댓글을 가리면 다시 복구할 수 없습니다.</Text>
      <Text>가리시겠습니까?</Text>
      <BtnContainer>
        <Button color="error" variant="contained" onClick={requestCryptoLiveCommentBlock}>
          차단
        </Button>
        <Button color="inherit" variant="contained" onClick={() => setModal(false)}>
          취소
        </Button>
      </BtnContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const Text = styled.div`
  font-size: 16px;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

export default BlockCommentModal;
