import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

type Table1Props = {
  head: string[];
  rows: (string | number | JSX.Element)[][];
};

/**
 * 데이터 형식
 *   head:[title1, title2, ....],
 *   rows:[
 *     [data1, data2, ...],
 *     [data1, data2, ...],
 *     ...
 *   ],
 */
const Table1 = ({ head, rows }: Table1Props) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {head.map((head, idx) => (
              <TableCell key={idx} align="center">{head}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => (
            <TableRow
              key={idx}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {row.map((text, idx) => (
                <TableCell key={idx} align="center">
                  {text}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Table1;
