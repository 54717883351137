import { SyntheticEvent, useEffect, useState } from "react";
import styled from "@emotion/styled";

import { getCryptoLiveList } from "@/api/cryptoApi";

import Table1 from "@/components/table/Table1";
import Pagination from "@/components/Pagination";
import Dialog from "@/components/Dialog";
import DeleteCrypto from "@/pages/crypto/components/DeleteCrypto";
import CryptoTabs from "@/pages/crypto/components/CryptoTabs";

import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

import { CRYPTO_PAGE_PER_CONTENT_COUNT, CRYPTO_TABLE_HEAD } from "@/pages/crypto/cryptoConst";
import { commas, convertUTCDateToLocalDate, convertYYYYMMDD_HHMMSS } from "@/utils/format";
import { Skeleton } from "@mui/material";
import LayoutContainer from "@/components/LayoutContainer";

const Crypto = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [modal, setModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [deleteContentKrName, setDeleteContentKrName] = useState("");
  const [cryptoLiveList, setCryptoLiveList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    requestCryptoLiveList();
  }, []);

  const requestCryptoLiveList = async () => {
    try {
      const res = await getCryptoLiveList({ page });
      const { cryptoList, totalCnt } = res.data;
      setCryptoLiveList(cryptoList);
      setTotalCount(totalCnt);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const handleDeleteModal = (userInfoId: number, email: string) => {
    setDeleteId(userInfoId);
    setDeleteContentKrName(email);
    setModal(true);
  };

  return (
    <LayoutContainer>
      <CryptoTabs />

      {loading ? (
        <Container>
          {[1, 2, 3, 4, 5].map((_, idx) => (
            <Skeleton key={idx} variant="rectangular" height={60} sx={{ marginBottom: "2px" }} />
          ))}
        </Container>
      ) : (
        <Container>
          <Dialog open={modal} onClose={() => setModal(false)} title="등록된 컨텐츠 삭제">
            <DeleteCrypto
              setModal={setModal}
              cryptoInfoId={deleteId}
              contentKrName={deleteContentKrName}
              requestCryptoLiveList={requestCryptoLiveList}
            />
          </Dialog>
          <Table1
            head={CRYPTO_TABLE_HEAD}
            rows={cryptoLiveList.map((data) => {
              const { ads_cnt, ads_usdp, content_name, crypto_info_id, end_date, reward_type, start_date, symbol } =
                data;
              return [
                crypto_info_id,
                <EditBtn
                  onClick={() =>
                    navigate("/cryptolive/edit", {
                      state: { id: crypto_info_id },
                    })
                  }
                >
                  {content_name}
                </EditBtn>,
                symbol,
                convertYYYYMMDD_HHMMSS(convertUTCDateToLocalDate(start_date)),
                convertYYYYMMDD_HHMMSS(convertUTCDateToLocalDate(end_date)),
                `${ads_cnt}/${commas(ads_usdp)}usdp`,
                `${reward_type}`.toUpperCase(),
                <Button
                  variant={"contained"}
                  color="error"
                  size="small"
                  onClick={() => handleDeleteModal(crypto_info_id, content_name)}
                >
                  삭제
                </Button>,
              ];
            })}
          />
          <Pagination
            page={page}
            selectPage={(page) => setPage(page)}
            totalPage={Math.ceil(totalCount / CRYPTO_PAGE_PER_CONTENT_COUNT)}
          />
        </Container>
      )}
    </LayoutContainer>
  );
};

const Container = styled.div`
  margin-top: 20px;
`;

const EditBtn = styled.div`
  color: #1976d2;
  cursor: pointer;
`;

export default Crypto;
