import { Fragment } from "react";
import styled from "@emotion/styled";

import { BrowserRouter, Routes, Route } from "react-router-dom";

// 대시보드
import Home from "@/pages/home/Home";

// 회원가입, 로그인
import Signin from "@/pages/sign/Signin";
import Signup from "@/pages/sign/Signup";

// 회원관리
import Account from "@/pages/account/Account";
import AccountUsdpLog from "@/pages/account/AccountUsdpLog";
import AccountPointLog from "@/pages/account/AccountPointLog";
import AccountRegister from "@/pages/account/AccountRegister";
import AccountEdit from "@/pages/account/AccountEdit";

// Crypto Live 관리
import Crypto from "@/pages/crypto/Crypto";
import CryptoRank from "@/pages/crypto/CryptoRank";
import CryptoRegister from "@/pages/crypto/CryptoRegister";
import CryptoRewardList from "@/pages/crypto/CryptoRewardList";
import CryptoEdit from "@/pages/crypto/CryptoEdit";
import CryptoCommentList from "@/pages/crypto/CryptoCommentList";

// High & Low 관리
import HighLow from "@/pages/highLow/HighLow";
import HighLowRegister from "@/pages/highLow/HighLowRegister";
import HighLowEdit from "@/pages/highLow/HighLowEdit";

// 운영 관리
import Notice from "@/pages/management/notice/Notice";
import NoticeRegister from "@/pages/management/notice/NoticeRegister";
import NoticeEdit from "@/pages/management/notice/NoticeEdit";
import Banner from "@/pages/management/banner/Banner";
import BannerRegister from "@/pages/management/banner/BannerRegister";
import BannerEdit from "@/pages/management/banner/BannerEdit";
import Terms from "@/pages/management/terms/Terms";
import TermsRegister from "@/pages/management/terms/TermsRegister";
import TermsEdit from "@/pages/management/terms/TermsEdit";
import Feedback from "@/pages/management/feedback/Feedback";
import Withdraw from "@/pages/management/withdraw/Withdraw";

// 관리자 관리
import Admin from "@/pages/admin/Admin";
import AdminEdit from "@/pages/admin/AdminEdit";
import HighLowCarry from "@/pages/highLow/HighLowCarry";
import AccountHighLowLog from "@/pages/account/AccountHighLowLog";

const Router = () => {
  return (
    <BrowserRouter>
      {process.env.REACT_APP_NODE_ENV === "development" && (
        <Fragment>
          <TESTBANNER>TEST 서버 입니다</TESTBANNER>
          <GAP />
        </Fragment>
      )}
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />

        <Route path="/home" element={<Home />} />

        {/* 회원 관리 */}
        <Route path="/account" element={<Account />} />
        <Route path="/account/register" element={<AccountRegister />} />
        <Route path="/account/edit" element={<AccountEdit />} />
        <Route path="/account/usdplog" element={<AccountUsdpLog />} />
        <Route path="/account/pointlog" element={<AccountPointLog />} />
        <Route path="/account/highlowlog" element={<AccountHighLowLog />} />

        {/* Crypto Live 관리 */}
        <Route path="/cryptolive/list" element={<Crypto />} />
        <Route path="/cryptolive/register" element={<CryptoRegister />} />
        <Route path="/cryptolive/edit" element={<CryptoEdit />} />
        <Route path="/cryptolive/rewardList" element={<CryptoRewardList />} />
        <Route path="/cryptolive/rank" element={<CryptoRank />} />
        <Route path="/cryptolive/commentList" element={<CryptoCommentList />} />

        {/* High & Low 관리 */}
        <Route path="/highlow/list" element={<HighLow />} />
        <Route path="/highlow/register" element={<HighLowRegister />} />
        <Route path="/highlow/edit" element={<HighLowEdit />} />
        <Route path="/highlow/carry" element={<HighLowCarry />} />

        {/* 운영 관리 */}
        <Route path="/management/notice" element={<Notice />} />
        <Route path="/management/notice/register" element={<NoticeRegister />} />
        <Route path="/management/notice/edit" element={<NoticeEdit />} />

        <Route path="/management/banner" element={<Banner />} />
        <Route path="/management/banner/register" element={<BannerRegister />} />
        <Route path="/management/banner/edit" element={<BannerEdit />} />

        <Route path="/management/feedback" element={<Feedback />} />

        <Route path="/management/terms" element={<Terms />} />
        <Route path="/management/terms/register" element={<TermsRegister />} />
        <Route path="/management/terms/edit" element={<TermsEdit />} />

        <Route path="/management/withdraw" element={<Withdraw />} />

        {/* 관리자 관리 */}
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin/edit" element={<AdminEdit />} />
      </Routes>
    </BrowserRouter>
  );
};

const TESTBANNER = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 40px;
  background-color: rgb(50, 50, 50);
  color: red;
  font-size: 20px;
  font-weight: bold;
  z-index: 10000;
`;

const GAP = styled.div`
  height: 40px;
`;

export default Router;
