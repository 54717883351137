import ReactDOM from "react-dom/client";
import Router from "./Router";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Provider } from "react-redux";
import { store } from "@/app/store";
import { StrictMode } from "react";
import "@/assets/css/reset.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <StrictMode>
    <Provider store={store}>
      <CssBaseline />
      <Router />
    </Provider>
  </StrictMode>
);
