import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { postManagementTermsList } from "@/api/managementApi";

import Dialog from "@/components/Dialog";
import LayoutContainer from "@/components/LayoutContainer";
import Pagination from "@/components/Pagination";
import Table1 from "@/components/table/Table1";
import ManagementTabBox from "@/pages/management/components/ManagementTabBox";
import TermsDelete from "@/pages/management/terms/TermsDelete";

import { Button, Skeleton } from "@mui/material";

import { TERMS_TABLE_HEAD } from "@/pages/management/managementConst";
import { convertYYYYMMDD_HHMMSS } from "@/utils/format";
import { useNavigate } from "react-router-dom";

const Terms = () => {
  const navigate = useNavigate();

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [deleteTitle, setDeleteTitle] = useState("");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [termsList, setTermsList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    requestManagementTermsList();
  }, [page]);

  const requestManagementTermsList = async () => {
    try {
      const res = await postManagementTermsList({ page });

      const { termsList, totalCnt } = res.data;

      if (termsList && totalCnt) {
        setTermsList(res.data.termsList);
        setTotalCount(res.data.totalCnt);
      } else {
        alert("데이터가 없습니다");
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <LayoutContainer>
      <Dialog open={deleteModal} onClose={() => setDeleteModal(false)} title="약관 삭제">
        <TermsDelete
          setModal={setDeleteModal}
          idx={deleteId}
          title={deleteTitle}
          requestManagementTermsList={requestManagementTermsList}
        />
      </Dialog>
      <ManagementTabBox />

      <TopBarContainer>
        <Button variant={"contained"} onClick={() => navigate("/management/terms/register")}>
          + 신규등록
        </Button>
      </TopBarContainer>

      {loading ? (
        <TableContainer>
          {[1, 2, 3, 4, 5].map((_, idx) => (
            <Skeleton key={idx} variant="rectangular" height={60} sx={{ marginBottom: "2px" }} />
          ))}
        </TableContainer>
      ) : (
        <TableContainer>
          {termsList?.length > 0 && (
            <Table1
              head={TERMS_TABLE_HEAD}
              rows={termsList.map((data) => {
                const { idx, title, insert_date, update_date } = data;
                return [
                  idx,
                  <EditBtn
                    onClick={() =>
                      navigate("/management/terms/edit", {
                        state: { id: idx },
                      })
                    }
                  >
                    {title}
                  </EditBtn>,
                  convertYYYYMMDD_HHMMSS(insert_date),
                  convertYYYYMMDD_HHMMSS(update_date),
                  <Button
                    variant={"contained"}
                    size="small"
                    color="error"
                    onClick={() => {
                      setDeleteId(idx);
                      setDeleteTitle(title);
                      setDeleteModal(true);
                    }}
                  >
                    삭제
                  </Button>,
                ];
              })}
            />
          )}
          <Pagination page={page} selectPage={(page) => setPage(page)} totalPage={Math.ceil(totalCount / 50)} />
        </TableContainer>
      )}
    </LayoutContainer>
  );
};

const TopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TableContainer = styled.div`
  margin-top: 20px;
`;

const EditBtn = styled.div`
  color: #1976d2;
  cursor: pointer;
`;

export default Terms;
