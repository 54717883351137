import styled from "@emotion/styled";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

type TooltipBoxProps = {
  userInfoId: number;
  email: string;
  imgUrl: string;
  nickname: string;
  handleDeleteModal: (id: number, email: string) => void;
};

const TooltipBox = ({ userInfoId, email, imgUrl, nickname, handleDeleteModal }: TooltipBoxProps) => {
  const navigate = useNavigate();

  const list = useRef<any>();
  const [length, setLength] = useState(0);

  useEffect(() => {
    setLength(list.current.children.length);
  }, []);

  return (
    <Container length={length}>
      <List ref={list}>
        <ListItem
          onClick={() =>
            navigate(`usdplog`, {
              state: {
                id: userInfoId,
                email,
                imgUrl,
                nickname,
              },
            })
          }
          sx={{ cursor: "pointer" }}
        >
          <ListItemText>USDP 로그</ListItemText>
        </ListItem>

        <ListItem
          onClick={() =>
            navigate(`pointlog`, {
              state: {
                id: userInfoId,
                email,
                imgUrl,
                nickname,
              },
            })
          }
          sx={{ cursor: "pointer" }}
        >
          <ListItemText>포인트 로그</ListItemText>
        </ListItem>

        <ListItem
          onClick={() =>
            navigate(`highlowlog`, {
              state: {
                id: userInfoId,
                email,
                imgUrl,
                nickname,
              },
            })
          }
          sx={{ cursor: "pointer" }}
        >
          <ListItemText>HighLow 로그</ListItemText>
        </ListItem>

        <ListItem
          onClick={() =>
            navigate(`edit`, {
              state: {
                id: userInfoId,
              },
            })
          }
          sx={{ cursor: "pointer" }}
        >
          <ListItemText style={{ color: "blue" }}>수정</ListItemText>
        </ListItem>

        <ListItem onClick={() => handleDeleteModal(userInfoId, email)} sx={{ cursor: "pointer" }}>
          <ListItemText style={{ color: "red" }}>삭제</ListItemText>
        </ListItem>
      </List>
    </Container>
  );
};

const Container = styled.div`
  width: 150px;
  height: ${({ length }: { length: number }) => `${length * 48 + 16}px`};
  background-color: "#fff";
`;

export default TooltipBox;
