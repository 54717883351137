import { useEffect, useState } from "react";

import { getAdminGradeList, postAdminList, postAdminBlock } from "@/api/adminApi";

import LayoutContainer from "@/components/LayoutContainer";
import Table1 from "@/components/table/Table1";
import Title1 from "@/components/Title1";

import { ADMIN_PAGE_PER_CONTENT_COUNT, ADMIN_TABLE_HEAD } from "@/pages/admin/adminConst";
import { convertUTCDateToLocalDate, convertYYYYMMDD_HHMMSS } from "@/utils/format";
import styled from "@emotion/styled";

import { useNavigate } from "react-router-dom";
import Pagination from "@/components/Pagination";
import { Button } from "@mui/material";
import Dialog from "@/components/Dialog";
import AdminApprove from "@/pages/admin/components/AdminApprove";

const Admin = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [adminList, setAdminList] = useState([]);
  const [approveModal, setApproveModal] = useState(false);
  const [approveId, setApproveId] = useState<number | null>(null);
  const [approveEmail, setApproveEmail] = useState("");
  const [gradeList, setGradeList] = useState([]);

  useEffect(() => {
    requestAdminList();
    requestGradeList();
  }, []);

  const requestAdminList = async () => {
    try {
      const res = await postAdminList({ page });

      if (res.status === 200) {
        const { adminList, totalCnt } = res.data;
        setAdminList(adminList);
        setTotalCount(totalCnt);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const requestBlock = async (id: number) => {
    try {
      const res = await postAdminBlock({ admin_info_id: id });

      if (res.status === 200) {
        requestAdminList();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const requestGradeList = async () => {
    try {
      const res = await getAdminGradeList();

      if (res.status === 200) {
        const gradeListToSelectData = res.data.gradeList.map((data: any) => ({
          value: data.grade_code,
          displayValue: data.description,
        }));
        setGradeList(gradeListToSelectData);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const statusBtn = (status: number, id: number, email: string) => {
    if (status === 1) {
      return (
        <Button
          variant={"contained"}
          color="error"
          size="small"
          onClick={() => {
            requestBlock(id);
          }}
        >
          차단
        </Button>
      );
    } else {
      return (
        <Button
          variant={"contained"}
          color="primary"
          size="small"
          onClick={() => {
            setApproveId(id);
            setApproveEmail(email);
            setApproveModal(true);
          }}
        >
          승인
        </Button>
      );
    }
  };

  return (
    <LayoutContainer>
      <Dialog open={approveModal} onClose={() => setApproveModal(false)} title="관리자 승인">
        {approveId && gradeList.length > 0 && (
          <AdminApprove
            setModal={setApproveModal}
            id={approveId}
            email={approveEmail}
            gradeList={gradeList}
            requestAdminList={requestAdminList}
          />
        )}
      </Dialog>

      <Title1 text="관리자 리스트" />

      <Table1
        head={ADMIN_TABLE_HEAD}
        rows={adminList.map((data) => {
          const { admin_info_id, email, name, description, status, insert_date } = data;
          const _status = status === 1 ? "승인" : status === -1 ? "차단" : "미승인";

          return [
            admin_info_id,
            email,
            <EditBtn
              onClick={() =>
                navigate("/admin/edit", {
                  state: { id: admin_info_id, gradeList: gradeList },
                })
              }
            >
              {name}
            </EditBtn>,
            description,
            convertYYYYMMDD_HHMMSS(convertUTCDateToLocalDate(insert_date)),
            _status,
            statusBtn(status, admin_info_id, email),
          ];
        })}
      />

      <Pagination
        page={page}
        totalPage={Math.ceil(totalCount / ADMIN_PAGE_PER_CONTENT_COUNT)}
        selectPage={(page) => setPage(page)}
      />
    </LayoutContainer>
  );
};

const EditBtn = styled.div`
  color: #1976d2;
  cursor: pointer;
`;

export default Admin;
