import { apost, apostImg } from "@/configs/AxiosConfig";

export const postImgBannerAdd = <T>(body: T) => {
  return apostImg("/img/banner/add", body);
};

export const postImgBannerUpdate = <T>(body: T) => {
  return apostImg("/img/banner/update", body);
};

export const postImgBannerDelete = <T>(body: T) => {
  return apost("/img/banner/delete", body);
};

export const postImgSymbolAdd = <T>(body: T) => {
  return apostImg("/img/symbol/add", body);
};

export const postImgSymbolUpdate = <T>(body: T) => {
  return apostImg("/img/symbol/update", body);
};

export const postImgSymbolDelete = <T>(body: T) => {
  return apost("/img/symbol/delete", body);
};

export const postImgProfileAdd = <T>(body: T) => {
  return apostImg("/img/profile/add", body);
};

export const postImgProfileUpdate = <T>(body: T) => {
  return apostImg("/img/profile/update", body);
};

export const postImgProfileDelete = <T>(body: T) => {
  return apost("/img/profile/delete", body);
};
