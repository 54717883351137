import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

const ManagementTabBox = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = useState("notice");

  useEffect(() => {
    const pathName = location.pathname.split("/")[2];
    if (pathName) return setValue(pathName);
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(`/management/${newValue}`);
  };

  return (
    <Box sx={{ width: "100%", marginBottom: "20px" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="공지관리" value="notice" />
          <Tab label="배너관리" value="banner" />
          <Tab label="피드백관리" value="feedback" />
          <Tab label="약관관리" value="terms" />
          <Tab label="출금관리" value="withdraw" />
        </Tabs>
      </Box>
    </Box>
  );
};

export default ManagementTabBox;
