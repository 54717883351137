import { getUserAgent } from "@/utils/public";

export const commas = (num: number) => {
  const parts = num.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const numbers = (value: string) => {
  return value.replace(/[^0-9]/g, "");
};

export const convertYYYYMMDD = (date: Date | string) => {
  return new Date(date).toISOString().substring(0, 10);
};

export const convertYYYYMMDD_HHMMSS = (date: Date | string) => {
  return new Date(date).toISOString().substring(0, 19).replace("T", " ");
};

export const convertUTCDateToLocalDate = (date: Date | string) => {
  const inputDate = new Date(date);
  const newDate = new Date(inputDate.getTime() - inputDate.getTimezoneOffset() * 60 * 1000);
  return newDate;
};

export const makeFormData = async (
  name: string,
  imgData: any,
  type: "normal" | "edit" | "kr" | "en" | "editKr" | "editEn",
  url?: string
) => {
  const formData = new FormData();

  formData.append(name, imgData);

  if (type === "edit") {
    if (url) {
      formData.append("img_path", url);
    }
  }
  if (type === "kr") {
    formData.append("img_type", "K");
  }
  if (type === "en") {
    formData.append("img_type", "E");
  }
  if (type === "editKr") {
    if (url) {
      formData.append("img_path", url);
      formData.append("img_type", "K");
    }
  }
  if (type === "editEn") {
    if (url) {
      formData.append("img_path", url);
      formData.append("img_type", "E");
    }
  }

  return formData;
};

export const makeImgServerUrl = (value: string) => {
  if (value.substring(0, 4) === "http") return value;
  return `${process.env.REACT_APP_S3_SERVERURL}${value}`;
};

export const maskingAddress = (value: string) => {
  if (value.length <= 20) return value;
  return `${value.substring(0, 10)}...${value.substring(value.length - 10, value.length)}`;
};

export const unescapeHtml = (str: string | null) => {
  if (str == null) {
    return "";
  }

  return str
    .replace(/&lt/g, "<")
    .replace(/&gt/g, ">")
    .replace(/&quot/g, '"')
    .replace(/&#38/g, "&")
    .replace(/&apos/g, "'");
};
