import { aget, apost } from "@/configs/AxiosConfig";

type AdminRegister = {
  name: string;
  email: string;
  password: string;
};
/**
 * 회원가입 api
 */
export const postAdminRegister = (body: AdminRegister) => {
  return apost("/admin/register", body);
};

type AdminLogin = {
  email: string;
  password: string;
  otp: string;
  otpType: "ios" | "android";
};
/**
 * 로그인 api
 */
export const postAdminLogin = (body: AdminLogin) => {
  return apost("/admin/login", body);
};

type AdminLogout = {};
/**
 * 로그아웃 api
 */
export const postAdminLogout = (body: AdminLogout) => {
  return apost("/admin/logout", body, true);
};

type AdminList = {
  page: number;
};
/**
 * 관리자 목록 조회 api
 */
export const postAdminList = (body: AdminList) => {
  return apost("/admin/list", body);
};

type AdminApprove = {
  admin_info_id: number;
  grade_code: string;
};
/**
 * 관리자 계정 가입 승인 처리 api
 */
export const postAdminApprove = (body: AdminApprove) => {
  return apost("/admin/approve", body);
};

type AdminInfo = {
  admin_info_id: number;
};
/**
 * 관리자 계정 정보 상세 조회 api
 */
export const postAdminInfo = (body: AdminInfo) => {
  return apost("/admin/info", body);
};

type AdminBlock = {
  admin_info_id: number;
};
/**
 * 관리자 계정 정보 상세 조회 api
 */
export const postAdminBlock = (body: AdminBlock) => {
  return apost("/admin/block", body);
};

type AdminUpdate = {
  admin_info_id: number;
  grade_code: string;
  name: string;
  password: string | null;
  status: number;
};
/**
 * 관리자 계정 정보 상세 조회 api
 */
export const postAdminUpdate = (body: AdminUpdate) => {
  return apost("/admin/update", body);
};

/**
 * 관리자 계정 정보 상세 조회 api
 */
export const getAdminGradeList = () => {
  return aget("/admin/grade/list");
};
