export const CRYPTO_TABLE_HEAD = [
  "인덱스",
  "이름",
  "코인",
  "시작 일시",
  "종료 일시",
  "광고 시청 횟수/ 보상 수량",
  "랭킹 보상 종류",
  "콘텐츠 삭제",
];

export const RANK_REWARD_TABLE_HEAD = ["보상 그룹", "최소 순위", "최대 순위", "보상 금액(1인)", "기능"];

export const CRYPTO_PAGE_PER_CONTENT_COUNT = 50;
export const CRYPTO_COMMENT_PAGE_PER_CONTENT_COUNT = 50;

// nft 추가로 개발되면 주석 풀어야됨(db 설명란에 있길레 준비해둠)
export const USERTYPE_SELECT_DATA = [
  {
    value: "all",
    displayValue: "전체",
  },
  // {
  //   value: "nft",
  //   displayValue: "NFT",
  // },
];

// 포인트도 추가로 개발되면 주석 풀어야됨(db 설명란에 있길레 준비해둠)
export const REWARD_SELECT_DATA = [
  {
    value: "handy",
    displayValue: "HANDY",
  },
  // {
  //   value: "point",
  //   displayValue: "POINT",
  // },
];

// 사용 코인 받아와서 1~20위까지 뿌려줘야 됨
export const USECOIN_SELECT_DATA = [
  {
    value: "BTCUSDT",
    displayValue: "BTCUSDT",
  },
];

// 나중에 베팅 타임 테스트 후에 오픈 (그래프 어떻게 그릴지도 봐야됨)
export const BATTIME_SELECT_DATA = [
  {
    value: "60",
    displayValue: "60",
  },
];
