import { postDashboard } from "@/api/mainApi";
import LayoutContainer from "@/components/LayoutContainer";
import Title1 from "@/components/Title1";
import Chart from "@/pages/home/components/Chart";
import DataBox from "@/pages/home/components/DataBox";
import SelectFilter from "@/pages/home/components/SelectFilter";
import { DashboardType } from "@/pages/home/homeType";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";

const Home = () => {
  const [dashboard, setDashboard] = useState<DashboardType>({
    today_register_cnt: 0,
    total_register_cnt: 0,
    total_reward_handy: 0,
    daily_visitor_list: [],
    daily_register_list: [],
  });

  useEffect(() => {
    const now = new Date();
    requestDashboard(new Date(new Date().setDate(now.getDate() - 7)), now);
  }, []);

  const requestDashboard = async (startDate: Date, endDate: Date) => {
    try {
      const res = await postDashboard({
        start_date: startDate,
        end_date: endDate,
      });

      setDashboard(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <LayoutContainer>
      <Title1 text="맞춰볼래 Live 대시보드" />
      <SelectFilter requestDashboard={requestDashboard} />
      <DataContainer>
        <Chart
          data={{
            daily_register_list: dashboard.daily_register_list,
            daily_visitor_list: dashboard.daily_visitor_list,
          }}
        />
        <DataBox dashboardData={dashboard} />
      </DataContainer>
    </LayoutContainer>
  );
};

const DataContainer = styled.div`
  display: flex;
  margin: 30px 0 0 30px;
`;

export default Home;
