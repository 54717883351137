import { PropsWithChildren } from "react";
import styled from "@emotion/styled";

import { postAdminLogout } from "@/api/adminApi";

import { useNavigate, useLocation } from "react-router-dom";
import { useAppSelector } from "@/app/hooks";
import { Button } from "@material-ui/core";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AccountBoxIcon from "@mui/icons-material/AccountBox";

import logo from "@/assets/img/HandypickLogo_c.png";

interface LayoutContainerProps extends PropsWithChildren {}

const LayoutContainer = ({ children }: LayoutContainerProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const SIDEBARWIDTH = 230;
  const NAVDATA = [
    {
      title: "회원관리",
      routeName: "/account",
      icon: <AccountBoxIcon />,
    },
    {
      title: "Crypto Live 관리",
      routeName: "/cryptolive/list",
      icon: <FolderCopyIcon />,
    },
    {
      title: "High & Low 관리",
      routeName: "/highlow/list",
      icon: <FolderCopyIcon />,
    },
    {
      title: "운영 관리",
      routeName: "/management/notice",
      icon: <ManageHistoryIcon />,
    },
    {
      title: "관리자 관리",
      routeName: "/admin",
      icon: <AdminPanelSettingsIcon />,
    },
  ];

  const userName = useAppSelector((state) => state.public.userName);

  const requestLogout = async () => {
    try {
      navigate("/");
      await postAdminLogout({});
      sessionStorage.removeItem("ACCESS_TOKEN");
      sessionStorage.removeItem("REFRESH_TOKEN");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container>
      <SideContainer w={SIDEBARWIDTH}>
        {process.env.REACT_APP_NODE_ENV === "development" && <GAP />}
        <Logo onClick={() => navigate("/home")}>
          <Img src={logo} alt="handypick" />
        </Logo>
        <List style={{ width: `${SIDEBARWIDTH}px` }}>
          {NAVDATA.map((data, index) => {
            const btnPath = data.routeName.split("/")[1];
            const currPath = location.pathname.split("/")[1];
            return (
              <ListItem key={index} disablePadding>
                <ListItemButton onClick={() => navigate(data.routeName)}>
                  <ListItemIcon sx={{ marginLeft: 2, marginY: 2 }}>{data.icon}</ListItemIcon>
                  <ListItemText primary={data.title} sx={{ color: btnPath === currPath ? "#1976d2" : "inherit" }} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </SideContainer>
      <ContentContainer pl={SIDEBARWIDTH}>
        <UserContainer>
          <Text>{`${userName}님 로그인 하셨습니다.`}</Text>
          <Button variant="contained" size="small" onClick={requestLogout}>
            로그아웃
          </Button>
        </UserContainer>
        <ContentWarpper>{children}</ContentWarpper>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  min-width: 100vw;
  max-width: 100vw;
  width: 100vw;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
`;

const SideContainer = styled.div<{ w: number }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: white;
  border-right: 1px solid black;
  width: ${({ w }) => `${w}px`};
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-family: "pretendard";
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
`;

const ContentContainer = styled.div<{ pl: number }>`
  padding-left: ${({ pl }) => `${pl}px`};
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
`;

const UserContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 20px 0 0;
`;

const Text = styled.div`
  margin-right: 20px;
  font-family: "pretendard";
  font-size: 16px;
`;

const ContentWarpper = styled.div`
  padding: 20px;
`;

const Img = styled.img`
  width: 100%;
  object-fit: contain;
`;

const GAP = styled.div`
  height: 40px;
`;

export default LayoutContainer;
