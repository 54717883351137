import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { postUserLogHighLow } from "@/api/accountApi";

import LayoutContainer from "@/components/LayoutContainer";
import Title1 from "@/components/Title1";
import Table1 from "@/components/table/Table1";
import Pagination from "@/components/Pagination";

import { useLocation } from "react-router-dom";
import { Paper } from "@mui/material";

import { commas, convertUTCDateToLocalDate, convertYYYYMMDD_HHMMSS, makeImgServerUrl } from "@/utils/format";
import { HIGHLOW_TABLE_HEAD, USDPLOG_PAGE_PER_CONTENT_COUNT } from "@/pages/account/accountConst";
import emptyImg from "@/assets/img/aic_menu_profile.png";

const AccountHighLowLog = () => {
  const location = useLocation();
  const { id, email, imgUrl, nickname } = location.state;

  const [page, setPage] = useState(1);

  const [highLowList, setHighLowList] = useState([]);
  const [totalCnt, setTotalCnt] = useState(0);
  const [oldHandy, setOldHandy] = useState(0);
  const [oldPoint, setOldPoint] = useState(0);
  const [handy, setHandy] = useState(0);

  useEffect(() => {
    requestUserLogHighLow();
  }, [page]);

  const requestUserLogHighLow = async () => {
    try {
      const res = await postUserLogHighLow({
        user_info_id: id,
        email,
        page,
      });

      const { highlowList, totalCnt } = res.data.usdpList;
      const { v1_handy, v1_point, v2_handy } = res.data.pointInfo[0];

      setHighLowList(highlowList);
      setTotalCnt(totalCnt);

      if (v1_handy) setOldHandy(v1_handy);
      if (v1_point) setOldPoint(v1_point);
      if (v2_handy) setHandy(v2_handy);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSelectPage = (page: number) => {
    setPage(page);
  };

  return (
    <LayoutContainer>
      <Title1 text="High & Low 로그" />

      <UserInfo>
        <Img alt="user image" src={imgUrl ? makeImgServerUrl(imgUrl) : emptyImg} />
        <TextContainer>
          <div>{nickname}</div>
          <div>{email}</div>
        </TextContainer>
        <Paper sx={{ padding: "10px", marginX: "10px" }}>V2 보유 HANDY : {commas(Number(handy.toFixed(2)))}</Paper>
        <Paper sx={{ padding: "10px", marginX: "10px" }}>V1 보유 HANDY : {commas(Number(oldHandy.toFixed(2)))}</Paper>
        <Paper sx={{ padding: "10px", marginX: "10px" }}>V1 보유 포인트 : {commas(Number(oldPoint.toFixed(2)))}</Paper>
      </UserInfo>

      <Table1
        head={HIGHLOW_TABLE_HEAD}
        rows={highLowList.map((data) => {
          const { highlow_info_id, high, low, high_result, low_result, high_status, low_status, ip_addr, insert_date } =
            data;

          return [
            highlow_info_id,
            convertYYYYMMDD_HHMMSS(convertUTCDateToLocalDate(insert_date)),
            <div>
              <HighPrice isCorrect={high_status === "win"}>{`High: ${commas(high)}`}</HighPrice>
              <LowPrice isCorrect={low_status === "win"}>{`Low: ${commas(low)}`}</LowPrice>
            </div>,
            <div>
              <HighPrice isCorrect={high_status === "win"}>{`High: ${
                high_result ? commas(high_result) : "진행중"
              }`}</HighPrice>
              <LowPrice isCorrect={low_status === "win"}>{`Low: ${
                low_result ? commas(low_result) : "진행중"
              }`}</LowPrice>
            </div>,
            <div>
              {high_status === "win" || low_status === "win"
                ? "리워드 지급"
                : high_status === "lose" && low_status === "lose"
                ? "예측 실패"
                : "진행중"}
            </div>,
            ip_addr,
          ];
        })}
      />
      <Pagination
        page={page}
        selectPage={handleSelectPage}
        totalPage={Math.ceil(totalCnt / USDPLOG_PAGE_PER_CONTENT_COUNT)}
      />
    </LayoutContainer>
  );
};

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin: 20px;
`;

const Img = styled.img`
  width: 50px;
  height: 50px;
  background-color: #363746;
  border-radius: 50%;
  object-fit: cover;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 15px;
`;

const HighPrice = styled.div<{ isCorrect: boolean }>`
  color: ${({ isCorrect }) => (isCorrect ? "#55cc1f" : "black")};
  font-weight: ${({ isCorrect }) => (isCorrect ? "bold" : "normal")};
`;

const LowPrice = styled.div<{ isCorrect: boolean }>`
  color: ${({ isCorrect }) => (isCorrect ? "#ff4d4d" : "black")};
  font-weight: ${({ isCorrect }) => (isCorrect ? "bold" : "normal")};
`;

export default AccountHighLowLog;
