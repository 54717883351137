import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { getManagementTermsInfo, postManagementTermsUpdate } from "@/api/managementApi";

import RegisterRowWrapper from "@/components/table/RegisterRowWrapper";
import LayoutContainer from "@/components/LayoutContainer";
import ManagementTabBox from "@/pages/management/components/ManagementTabBox";

import { useLocation, useNavigate } from "react-router-dom";
import { Button, Divider, Skeleton, TextField } from "@mui/material";

const TermsEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = location.state;

  const [title, setTitle] = useState("");
  const [krTitle, setKrTitle] = useState("");
  const [krContent, setKrContent] = useState("");
  const [enTitle, setEnTitle] = useState("");
  const [enContent, setEnContent] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    requestManagementTermsInfo();
  }, []);

  const requestManagementTermsInfo = async () => {
    try {
      const res = await getManagementTermsInfo({ idx: id });

      const { title, k_title, k_content, e_title, e_content } = res.data[0];

      setTitle(title);
      setKrTitle(k_title);
      setKrContent(k_content);
      setEnTitle(e_title);
      setEnContent(e_content);

      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const requestManagementTermsUpdate = async () => {
    try {
      const res = await postManagementTermsUpdate({
        idx: id,
        title,
        k_title: krTitle,
        k_content: krContent,
        e_title: enTitle,
        e_content: enContent,
      });

      if (res.status === 200) {
        navigate("/management/terms");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <LayoutContainer>
      <ManagementTabBox />
      {loading ? (
        <Container>
          <Skeleton variant="rectangular" width={950} height={40} sx={{ marginBottom: "20px" }} />
          <Divider sx={{ marginBottom: "20px" }} />
          <Skeleton variant="rectangular" width={950} height={40} sx={{ marginBottom: "20px" }} />
          <Skeleton variant="rectangular" width={950} height={109} sx={{ marginBottom: "20px" }} />
          <Skeleton variant="rectangular" width={950} height={40} sx={{ marginBottom: "20px" }} />
          <Skeleton variant="rectangular" width={950} height={109} />
        </Container>
      ) : (
        <Container>
          <RegisterRowWrapper title="제목">
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              placeholder="관리자페이지에서 표시되는 제목입니다"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </RegisterRowWrapper>

          <Divider sx={{ marginBottom: "20px" }} />

          <RegisterRowWrapper title="국문 제목">
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              placeholder="국문 제목을 입력하세요"
              value={krTitle}
              onChange={(e) => setKrTitle(e.target.value)}
            />
          </RegisterRowWrapper>

          <RegisterRowWrapper title="국문 내용">
            <TextField
              id="outlined-multiline-static"
              multiline
              fullWidth
              rows={4}
              placeholder="국문 내용을 입력하세요"
              size="small"
              value={krContent}
              onChange={(e) => setKrContent(e.target.value)}
            />
          </RegisterRowWrapper>

          <RegisterRowWrapper title="영문 제목">
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              placeholder="영문 제목을 입력하세요"
              value={enTitle}
              onChange={(e) => setEnTitle(e.target.value)}
            />
          </RegisterRowWrapper>

          <RegisterRowWrapper title="영문 내용">
            <TextField
              id="outlined-multiline-static"
              fullWidth
              multiline
              rows={4}
              placeholder="영문 내용을 입력하세요"
              size="small"
              value={enContent}
              onChange={(e) => setEnContent(e.target.value)}
            />
          </RegisterRowWrapper>

          <BtnContainer>
            <Button variant="contained" sx={{ width: "150px" }} onClick={requestManagementTermsUpdate}>
              수정
            </Button>
            <Button variant="outlined" sx={{ width: "150px" }} onClick={() => navigate("/management/terms")}>
              취소
            </Button>
          </BtnContainer>
        </Container>
      )}
    </LayoutContainer>
  );
};

const Container = styled.div`
  margin-bottom: 100px;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
`;

export default TermsEdit;
