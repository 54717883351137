import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { postHighLowInfo, postHighLowUpdate } from "@/api/highLowApi";
import {
  postImgBannerDelete,
  postImgBannerUpdate,
  postImgSymbolUpdate,
  postImgBannerAdd,
  postImgSymbolAdd,
} from "@/api/api";

import LayoutContainer from "@/components/LayoutContainer";
import Select1 from "@/components/Select1";
import RegisterRowWrapper from "@/components/table/RegisterRowWrapper";
import ImgUpload from "@/components/ImgUpload";
import CryptoTabs from "@/pages/crypto/components/CryptoTabs";

import { useLocation, useNavigate } from "react-router-dom";
import { Button, TextField, Typography } from "@mui/material";

import { REWARD_SELECT_DATA, USECOIN_SELECT_DATA, USERTYPE_SELECT_DATA } from "@/pages/crypto/cryptoConst";
import { convertUTCDateToLocalDate, makeImgServerUrl } from "@/utils/format";
import Title1 from "@/components/Title1";

const HighLowEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = location.state;

  const [contentKrName, setContentKrName] = useState("");
  const [contentEnName, setContentEnName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userType, setUserType] = useState("all");
  const [rewardType, setRewardType] = useState("handy");
  const [reward, setReward] = useState(0);
  const [playMaxCnt, setPlayMaxCnt] = useState(0);
  const [useCoin, setUseCoin] = useState("BTCUSDT");
  const [bannerUrl, setBannerUrl] = useState<any>();
  const [bannerForm, setBannerForm] = useState<any>();
  const [symbolUrl, setSymbolUrl] = useState<any>();
  const [symbolForm, setSymbolForm] = useState<any>();

  const [bannerOldUrl, setBannerOldUrl] = useState("");
  const [symbolOldUrl, setSymbolOldUrl] = useState("");

  useEffect(() => {
    requestHighLowInfo();
  }, []);

  const requestHighLowInfo = async () => {
    try {
      const res = await postHighLowInfo({ highlow_info_id: id });

      if (res.status === 200) {
        const {
          content_name,
          content_e_name,
          start_date,
          end_date,
          participants,
          reward_type,
          reward,
          play_max_cnt,
          symbol,
          img,
          symbol_img,
        } = res.data[0];

        setContentKrName(content_name);
        setContentEnName(content_e_name);
        setStartDate(convertUTCDateToLocalDate(start_date).toISOString().substring(0, 16));
        setEndDate(convertUTCDateToLocalDate(end_date).toISOString().substring(0, 16));
        setUserType(participants === 2 ? "nft" : "all");
        setRewardType(reward_type);
        setReward(reward);
        setPlayMaxCnt(play_max_cnt);
        setUseCoin(symbol);

        if (img) {
          setBannerUrl(makeImgServerUrl(img));
          setBannerOldUrl(img);
        }
        if (symbol_img) {
          setSymbolUrl(makeImgServerUrl(symbol_img));
          setSymbolOldUrl(symbol_img);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const requestHighLowUpdate = async () => {
    if (!contentKrName) return alert("콘텐츠 국문 이름을 입력하세요");
    if (!contentEnName) return alert("콘텐츠 영문 이름을 입력하세요");
    if (!symbolUrl) return alert("심볼 이미지를 등록하세요");
    if (!startDate) return alert("콘텐츠 시작 일시를 입력하세요");
    if (!endDate) return alert("콘텐츠 종료 일시를 입력하세요");
    if (!reward) return alert("보상 수량을 입력하세요");
    if (!playMaxCnt) return alert("참여 제한 횟수를 입력하세요");

    try {
      let bannerImgUrl: any = bannerOldUrl;
      if (bannerForm) {
        if (bannerImgUrl) {
          const bannerImgRes = await postImgBannerUpdate(bannerForm);
          bannerImgUrl = bannerImgRes.data.img;
        } else {
          const bannerImgRes = await postImgBannerAdd(bannerForm);
          bannerImgUrl = bannerImgRes.data.img;
        }
      } else {
        if (bannerUrl === null) {
          if (bannerOldUrl) {
            await postImgBannerDelete({
              img_path: bannerOldUrl,
            });
          }
          bannerImgUrl = null;
        }
      }

      let symbolImgUrl: any = symbolOldUrl;
      if (symbolForm) {
        if (symbolImgUrl) {
          const symbolImgRes = await postImgSymbolUpdate(symbolForm);
          symbolImgUrl = symbolImgRes.data.symbol_img;
        } else {
          const symbolImgRes = await postImgSymbolAdd(symbolForm);
          symbolImgUrl = symbolImgRes.data.symbol_img;
        }
      }

      const res = await postHighLowUpdate({
        highlow_info_id: id,
        content_name: contentKrName,
        content_e_name: contentEnName,
        img: bannerImgUrl,
        symbol_img: symbolImgUrl,
        start_date: startDate,
        end_date: endDate,
        participants: userType === "nft" ? 2 : 1,
        reward_type: rewardType,
        reward,
        play_max_cnt: playMaxCnt,
        symbol: useCoin,
      });

      if (res.status === 200) {
        navigate("/highlow/list");
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <LayoutContainer>
      <Title1 text="콘텐츠 수정" />
      <Container>
        <RegisterRowWrapper title="콘텐츠 국문 이름">
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="콘텐츠 국문 이름을 입력해주세요"
            value={contentKrName}
            onChange={(e) => setContentKrName(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="콘텐츠 영문 이름">
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="콘텐츠 영문 이름을 입력해주세요"
            value={contentEnName}
            onChange={(e) => setContentEnName(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="배너 이미지">
          <ImgUpload
            url={bannerUrl}
            setUrl={setBannerUrl}
            setForm={setBannerForm}
            width={160}
            height={100}
            type="edit"
            name="banner_image"
            editUrl={bannerOldUrl}
          />
          <TextContainer>
            <Typography>🔥 8:5 비율의 이미지를 업로드 해주세요</Typography>
            <Typography>🔥 권장 해상도는 960 × 600 입니다</Typography>
          </TextContainer>
        </RegisterRowWrapper>

        <RegisterRowWrapper title="코인 이미지">
          <ImgUpload
            url={symbolUrl}
            setUrl={setSymbolUrl}
            setForm={setSymbolForm}
            width={100}
            height={100}
            type="edit"
            name="symbol_image"
            editUrl={symbolOldUrl}
          />
          <TextContainer>
            <Typography>🔥 1:1 비율의 이미지를 업로드 해주세요</Typography>
            <Typography>🔥 권장 해상도는 60 × 60 입니다</Typography>
          </TextContainer>
        </RegisterRowWrapper>

        <RegisterRowWrapper title="시작일시">
          <TextField
            id="datetime-local"
            type="datetime-local"
            sx={{ width: 250 }}
            InputLabelProps={{
              shrink: true,
            }}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="종료일시">
          <TextField
            id="datetime-local"
            type="datetime-local"
            sx={{ width: 250 }}
            InputLabelProps={{
              shrink: true,
            }}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="참여대상">
          <Select1
            selectData={USERTYPE_SELECT_DATA}
            value={userType}
            onChange={(selectData) => setUserType(selectData)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="보상 종류">
          <Select1
            selectData={REWARD_SELECT_DATA}
            value={rewardType}
            onChange={(selectData) => setRewardType(selectData)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="보상 수량">
          <TextField
            variant="outlined"
            sx={{ width: "400px" }}
            size="small"
            placeholder="보상 수량을 입력하세요"
            helperText="* 매일 지급/이월되는 보상 수량"
            value={reward}
            onChange={(e) => {
              if (!Number(e.target.value)) return;
              setReward(Number(e.target.value));
            }}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="참여 제한 횟수">
          <TextField
            variant="outlined"
            sx={{ width: "400px" }}
            size="small"
            placeholder="참여 제한 횟수를 입력하세요"
            helperText="* 하루에 예측할 수 있는 횟수"
            value={playMaxCnt}
            onChange={(e) => {
              if (!Number(e.target.value)) return;
              setPlayMaxCnt(Number(e.target.value));
            }}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="사용 코인">
          <Select1 selectData={USECOIN_SELECT_DATA} value={useCoin} onChange={(selectData) => setUseCoin(selectData)} />
        </RegisterRowWrapper>

        <BtnContainer>
          <Button variant="contained" sx={{ width: "150px" }} onClick={requestHighLowUpdate}>
            수정
          </Button>
          <Button variant="outlined" sx={{ width: "150px" }} onClick={() => navigate("/highlow/list")}>
            취소
          </Button>
        </BtnContainer>
      </Container>
    </LayoutContainer>
  );
};

const Container = styled.div`
  margin-top: 20px;
  padding-bottom: 100px;
`;

const AlignContainer = styled.div`
  display: flex;
  gap: 5px;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
`;

const TextContainer = styled.div`
  margin-top: 10px;
`;

export default HighLowEdit;
