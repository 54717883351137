import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import LayoutContainer from "@/components/LayoutContainer";
import Title1 from "@/components/Title1";
import Table1 from "@/components/table/Table1";
import Pagination from "@/components/Pagination";

import { useLocation } from "react-router-dom";
import { Paper } from "@mui/material";

import { commas, convertUTCDateToLocalDate, convertYYYYMMDD_HHMMSS } from "@/utils/format";
import { USDPLOG_PAGE_PER_CONTENT_COUNT, USDP_TABLE_HEAD } from "@/pages/account/accountConst";
import emptyImg from "@/assets/img/aic_menu_profile.png";
import { getUserLogUsdp } from "@/api/accountApi";

const AccountUsdpLog = () => {
  const location = useLocation();
  const { id, email, imgUrl, nickname } = location.state;

  const [page, setPage] = useState(1);
  const [usdp, setUsdp] = useState(0);
  const [usdpList, setUsdpList] = useState([]);
  const [totalCnt, setTotalCnt] = useState(0);

  useEffect(() => {
    requestUserLogUsdp();
  }, [page]);

  const requestUserLogUsdp = async () => {
    try {
      const res = await getUserLogUsdp({
        user_info_id: id,
        email,
        page,
      });

      const { usdpList, usdp } = res.data;

      setUsdp(usdp);
      setTotalCnt(usdpList.totalCnt);
      setUsdpList(usdpList.usdpList);

      console.log(res);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSelectPage = (page: number) => {
    setPage(page);
  };

  return (
    <LayoutContainer>
      <Title1 text="회원 USDP 로그" />
      <UserInfo>
        <Img alt="user image" src={imgUrl ? imgUrl : emptyImg} />
        <TextContainer>
          <div>{nickname}</div>
          <div>{email}</div>
        </TextContainer>
        <Paper sx={{ padding: "10px" }}>보유 USDP : {commas(Number(usdp.toFixed(2)))}</Paper>
      </UserInfo>
      <Table1
        head={USDP_TABLE_HEAD}
        rows={usdpList.map((data) => {
          const { idx, insert_date, use_case, usdp, content, ip_addr } = data;

          return [
            idx,
            convertYYYYMMDD_HHMMSS(convertUTCDateToLocalDate(insert_date)),
            `${use_case === "사용" ? "-" : "+"} ${commas(usdp)}`,
            content,
            ip_addr,
          ];
        })}
      />
      <Pagination
        page={page}
        selectPage={handleSelectPage}
        totalPage={Math.ceil(totalCnt / USDPLOG_PAGE_PER_CONTENT_COUNT)}
      />
    </LayoutContainer>
  );
};

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin: 20px;
`;

const Img = styled.img`
  width: 50px;
  height: 50px;
  background-color: #363746;
  border-radius: 50%;
  object-fit: cover;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 25px 0 15px;
`;

export default AccountUsdpLog;
