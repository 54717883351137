import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { Button, TextField } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { convertYYYYMMDD } from "@/utils/format";

type SelectFilterProps = {
  requestDashboard: (startDate: Date, endDate: Date) => void;
};

const SelectFilter = ({ requestDashboard }: SelectFilterProps) => {
  const today = convertYYYYMMDD(new Date());
  const weekAgo = convertYYYYMMDD(new Date(new Date().setDate(new Date().getDate() - 7)));
  const monthAgo = convertYYYYMMDD(new Date(new Date().setMonth(new Date().getMonth() - 1)));

  const [startDate, setStartDate] = useState(weekAgo);
  const [endDate, setEndDate] = useState(today);

  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setTabIdx(newValue);
  // };

  useEffect(() => {
    requestDashboard(new Date(startDate), new Date(endDate));
  }, [startDate, endDate]);

  return (
    <Container>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          {/* <Tabs value={tabIdx} onChange={handleChange} aria-label="basic tabs example"> */}
          <Tabs value={0} aria-label="basic tabs example">
            <Tab label="전체" />
          </Tabs>
        </Box>
      </Box>
      <Button
        variant={startDate === weekAgo && endDate === today ? "contained" : "outlined"}
        onClick={() => {
          setStartDate(weekAgo);
          setEndDate(today);
        }}
      >
        7일
      </Button>
      <Button
        variant={startDate === monthAgo && endDate === today ? "contained" : "outlined"}
        onClick={() => {
          setStartDate(monthAgo);
          setEndDate(today);
        }}
      >
        30일
      </Button>
      <TextField
        id="date"
        type="date"
        sx={{ minWidth: "170px" }}
        size="small"
        InputLabelProps={{
          shrink: true,
        }}
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
      />
      <div>-</div>
      <TextField
        id="date"
        type="date"
        sx={{ minWidth: "170px" }}
        size="small"
        InputLabelProps={{
          shrink: true,
        }}
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0 10px;
`;

export default SelectFilter;
