import styled from "@emotion/styled";

import { RegisterDataType, VisitorDataType } from "@/pages/home/homeType";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { convertYYYYMMDD } from "@/utils/format";

type ChartProps = {
  data: {
    daily_visitor_list: VisitorDataType[];
    daily_register_list: RegisterDataType[];
  };
};

const Chart = ({ data }: ChartProps) => {
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

  const labels = data.daily_visitor_list.map((data) => convertYYYYMMDD(data.date));

  const chartData = {
    labels,
    datasets: [
      {
        label: "신규 가입자", // 상단에 있는 텍스트
        data: data.daily_register_list.map((data) => data.insert_date),
        borderColor: "rgb(230,150,50)", // 선 색상
        backgroundColor: "white", // dott 색상
      },
      {
        label: "일별 접속자",
        data: data.daily_visitor_list.map((data) => data.login_cnt),
        borderColor: "rgb(53, 52, 225)",
        backgroundColor: "white",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
  };

  return (
    <Container>
      <Line options={options} data={chartData} />
    </Container>
  );
};

const Container = styled.div`
  width: 1200px;
  height: 600px;
  margin-right: 30px;
`;

export default Chart;
