export const HIGHLOW_TABLE_HEAD = [
  "인덱스",
  "이름",
  "코인",
  "시작 일시",
  "종료 일시",
  "참여 제한 횟수",
  "보상 수량",
  "랭킹 보상 종류",
  "콘텐츠 삭제",
];

export const HIGHLOW_CARRY_TABLE_HEAD = [
  "인덱스",
  "회차",
  "코인 항목",
  "참여자 수",
  "당첨자 수",
  "실제 가격",
  "시작 시간",
  "보상 수량",
];

export const HIGHLOW_PAGE_PER_CONTENT_COUNT = 50;

export const HIGHLOW_CARRY_PAGE_PER_CONTENT_COUNT = 50;

// nft 추가로 개발되면 주석 풀어야됨(db 설명란에 있길레 준비해둠)
export const USERTYPE_SELECT_DATA = [
  {
    value: "all",
    displayValue: "전체",
  },
  // {
  //   value: "nft",
  //   displayValue: "NFT",
  // },
];

// 포인트도 추가로 개발되면 주석 풀어야됨(db 설명란에 있길레 준비해둠)
export const REWARD_SELECT_DATA = [
  {
    value: "handy",
    displayValue: "HANDY",
  },
  // {
  //   value: "point",
  //   displayValue: "POINT",
  // },
];

// 사용 코인 받아와서 1~20위까지 뿌려줘야 됨
export const USECOIN_SELECT_DATA = [
  {
    value: "BTCUSDT",
    displayValue: "BTCUSDT",
  },
];
