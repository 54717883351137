import { postHighLowCarryOverRewardInfo } from "@/api/highLowApi";
import RegisterRowWrapper from "@/components/table/RegisterRowWrapper";
import WinnerCard from "@/pages/highLow/components/WinnerCard";
import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

type WinnerDetailProps = {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  hclInfoId: number;
  highPrice: number;
  lowPrice: number;
};

const WinnerDetail = ({ setModal, hclInfoId, highPrice, lowPrice }: WinnerDetailProps) => {
  const [infoList, setInfoList] = useState([]);

  useEffect(() => {
    requestHighLowCarryOverRewardInfo();
  }, []);

  const requestHighLowCarryOverRewardInfo = async () => {
    try {
      const res = await postHighLowCarryOverRewardInfo({ hcl_info_id: hclInfoId });

      setInfoList(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container>
      <RegisterRowWrapper title="당첨자수">
        <Typography sx={{ maxWidth: "400px", height: "40px", display: "flex", alignItems: "center" }}>
          {infoList.length}
        </Typography>
      </RegisterRowWrapper>

      <RegisterRowWrapper title="실제가격">
        <Typography sx={{ maxWidth: "400px", height: "40px", display: "flex", alignItems: "center" }}>
          {`High: ${highPrice} / Low: ${lowPrice}`}
        </Typography>
      </RegisterRowWrapper>

      <RegisterRowWrapper title="당첨자">
        <Typography sx={{ maxWidth: "400px", maxHeight: "40vh", overflow: "auto", paddingTop: "9px" }}>
          {infoList.map((data) => {
            const { user_info_id, email, high, low } = data;
            return <WinnerCard userInfoId={user_info_id} email={email} highPrice={high} lowPrice={low} />;
          })}
        </Typography>
      </RegisterRowWrapper>

      <BtnContainer>
        <Button variant={"contained"} sx={{ width: "150px" }} color="inherit" onClick={() => setModal(false)}>
          닫기
        </Button>
      </BtnContainer>
    </Container>
  );
};

const Container = styled.div``;

const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`;

export default WinnerDetail;
