import { useState } from "react";
import styled from "@emotion/styled";

import { postManagementTermsAdd } from "@/api/managementApi";

import RegisterRowWrapper from "@/components/table/RegisterRowWrapper";
import LayoutContainer from "@/components/LayoutContainer";
import ManagementTabBox from "@/pages/management/components/ManagementTabBox";

import { useNavigate } from "react-router-dom";
import { Button, Divider, TextField } from "@mui/material";

const TermsRegister = () => {
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [krTitle, setKrTitle] = useState("");
  const [krContent, setKrContent] = useState("");
  const [enTitle, setEnTitle] = useState("");
  const [enContent, setEnContent] = useState("");

  const requestManagementTermsAdd = async () => {
    try {
      const res = await postManagementTermsAdd({
        title,
        k_title: krTitle,
        k_content: krContent,
        e_title: enTitle,
        e_content: enContent,
      });

      if (res.status === 200) {
        navigate("/management/terms");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <LayoutContainer>
      <ManagementTabBox />
      <Container>
        <RegisterRowWrapper title="제목">
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="관리자페이지에서 표시되는 제목입니다"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </RegisterRowWrapper>

        <Divider sx={{ marginBottom: "20px" }} />

        <RegisterRowWrapper title="국문 제목">
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="국문 제목을 입력하세요"
            value={krTitle}
            onChange={(e) => setKrTitle(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="국문 내용">
          <TextField
            id="outlined-multiline-static"
            multiline
            fullWidth
            rows={4}
            placeholder="국문 내용을 입력하세요"
            size="small"
            value={krContent}
            onChange={(e) => setKrContent(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="영문 제목">
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="영문 제목을 입력하세요"
            value={enTitle}
            onChange={(e) => setEnTitle(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="영문 내용">
          <TextField
            id="outlined-multiline-static"
            fullWidth
            multiline
            rows={4}
            placeholder="영문 내용을 입력하세요"
            size="small"
            value={enContent}
            onChange={(e) => setEnContent(e.target.value)}
          />
        </RegisterRowWrapper>

        <BtnContainer>
          <Button
            variant="contained"
            sx={{ width: "150px" }}
            onClick={requestManagementTermsAdd}
          >
            저장
          </Button>
          <Button
            variant="outlined"
            sx={{ width: "150px" }}
            onClick={() => navigate("/management/notice")}
          >
            취소
          </Button>
        </BtnContainer>
      </Container>
    </LayoutContainer>
  );
};

const Container = styled.div`
  margin-bottom: 100px;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

export default TermsRegister;
