import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogComponent from "@mui/material/Dialog";
import { DialogContent } from "@mui/material";

interface DialogProps extends React.PropsWithChildren {
  open: boolean;
  onClose: () => void;
  title: string;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
}

const Dialog = ({ onClose, open, title, children, size }: DialogProps) => {
  return (
    <DialogComponent fullWidth onClose={onClose} open={open} maxWidth={size ? size : "sm"}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
    </DialogComponent>
  );
};

export default Dialog;
