import { apost } from "@/configs/AxiosConfig";

type Dashboard = {};
/**
 * 로그인 후 home 에서 대시보드 데이터 받아오는 api
 * @param body 빈 오프젝트 ({}) 로 보내면 됨
 * @returns
 */
export const postDashboard = (body: Dashboard) => {
  return apost("/dashboard", body);
};
