import axios from "axios";

const axiosConfig = {
  //withCredentials: true,
};

const instance = axios.create();
const _instance = axios.create();

instance.interceptors.request.use(
  (request) => {
    return request;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const {
      config,
      response: { status, message },
    } = error;

    if (status === 403) {
      const originalRequset = config;
      const refreshToken = sessionStorage.getItem("REFRESH_TOKEN");

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${refreshToken}`,
      };

      try {
        const { data } = await _instance({
          method: "GET",
          url: process.env.REACT_APP_BASEURL + "/admin/auth",
          headers: headers,
        });

        const newAccessToken = data.data.accessToken;
        const newRefreshToken = data.data.refreshToken;

        originalRequset.headers["Authorization"] = `Bearer ${newAccessToken}`;

        sessionStorage.setItem("ACCESS_TOKEN", newAccessToken);
        sessionStorage.setItem("REFRESH_TOKEN", newRefreshToken);

        const res = await _instance(originalRequset);
        return res;
      } catch (err: any) {
        const { status, message } = err.response.data;
        if (status === 403) {
          alert(message);
          window.location.href = "/";
        }
      }
    }

    return Promise.reject(error);
  }
);

export async function aget(url: string) {
  const token = sessionStorage.getItem("ACCESS_TOKEN");

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  return await instance({
    ...axiosConfig,
    url: process.env.REACT_APP_BASEURL + url,
    method: "GET",
    timeout: 30000,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export async function apost<T>(url: string, data: T, isRefrashToken?: boolean) {
  const token = isRefrashToken ? sessionStorage.getItem("REFRESH_TOKEN") : sessionStorage.getItem("ACCESS_TOKEN");

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  return await instance({
    ...axiosConfig,
    url: process.env.REACT_APP_BASEURL + url,
    method: "POST",
    timeout: 30000,
    data: data,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export async function apostImg<T>(url: string, data: T) {
  const ACCESS_TOKEN = sessionStorage.getItem("ACCESS_TOKEN");

  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  };

  return await instance({
    ...axiosConfig,
    url: process.env.REACT_APP_BASEURL + url,
    method: "POST",
    timeout: 30000,
    data,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
