import { SyntheticEvent, useEffect, useState } from "react";
import styled from "@emotion/styled";

import Table1 from "@/components/table/Table1";
import Pagination from "@/components/Pagination";
import Dialog from "@/components/Dialog";

import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

import { commas, convertUTCDateToLocalDate, convertYYYYMMDD_HHMMSS } from "@/utils/format";
import { Skeleton } from "@mui/material";
import LayoutContainer from "@/components/LayoutContainer";
import HighLowTabs from "@/pages/highLow/components/HighLowTabs";
import DeleteHighLow from "@/pages/highLow/components/DeleteHighLow";
import { HIGHLOW_PAGE_PER_CONTENT_COUNT, HIGHLOW_TABLE_HEAD } from "@/pages/highLow/highLowConst";
import { postHighLowList } from "@/api/highLowApi";
import { HighLowList } from "@/pages/highLow/highlowType";

const HighLow = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [modal, setModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [deleteContentKrName, setDeleteContentKrName] = useState("");
  const [highLowList, setHighLowList] = useState<HighLowList[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    requestHighLowList();
  }, []);

  const requestHighLowList = async () => {
    try {
      const res = await postHighLowList({ page });

      const { highlowList, totalCnt } = res.data;
      setHighLowList(highlowList);
      setTotalCount(totalCnt);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const handleDeleteModal = (id: number, name: string) => {
    setDeleteId(id);
    setDeleteContentKrName(name);
    setModal(true);
  };

  return (
    <LayoutContainer>
      <HighLowTabs />

      {loading ? (
        <Container>
          {[1, 2, 3, 4, 5].map((_, idx) => (
            <Skeleton key={idx} variant="rectangular" height={60} sx={{ marginBottom: "2px" }} />
          ))}
        </Container>
      ) : (
        <Container>
          <Dialog open={modal} onClose={() => setModal(false)} title="등록된 컨텐츠 삭제">
            <DeleteHighLow
              setModal={setModal}
              highLowInfoId={deleteId}
              contentKrName={deleteContentKrName}
              requestHighLowList={requestHighLowList}
            />
          </Dialog>

          <Table1
            head={HIGHLOW_TABLE_HEAD}
            rows={highLowList.map((data) => {
              const { content_name, highlow_info_id, end_date, reward_type, start_date, symbol, reward, play_max_cnt } =
                data;
              return [
                highlow_info_id,
                <EditBtn
                  onClick={() =>
                    navigate("/highlow/edit", {
                      state: { id: highlow_info_id },
                    })
                  }
                >
                  {content_name}
                </EditBtn>,
                symbol,
                convertYYYYMMDD_HHMMSS(convertUTCDateToLocalDate(start_date)),
                convertYYYYMMDD_HHMMSS(convertUTCDateToLocalDate(end_date)),
                play_max_cnt,
                commas(reward),
                `${reward_type}`.toUpperCase(),
                <Button
                  variant={"contained"}
                  color="error"
                  size="small"
                  onClick={() => handleDeleteModal(highlow_info_id, content_name)}
                >
                  삭제
                </Button>,
              ];
            })}
          />
          <Pagination
            page={page}
            selectPage={(page) => setPage(page)}
            totalPage={Math.ceil(totalCount / HIGHLOW_PAGE_PER_CONTENT_COUNT)}
          />
        </Container>
      )}
    </LayoutContainer>
  );
};

const Container = styled.div`
  margin-top: 20px;
`;

const EditBtn = styled.div`
  color: #1976d2;
  cursor: pointer;
`;

export default HighLow;
