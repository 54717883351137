import React from "react";
import styled from "@emotion/styled";

import { deleteUserDelete } from "@/api/accountApi";

import { Button } from "@mui/material";

type AccountDeleteProps = {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  userInfoId: number;
  email: string;
  requestUserList: () => void;
};

const AccountDelete = ({ setModal, userInfoId, email, requestUserList }: AccountDeleteProps) => {
  const requestDeleteUserInfo = async () => {
    try {
      const res = await deleteUserDelete({
        user_info_id: userInfoId,
        email,
      });

      if (res.status === 200) {
        setModal(false);
        alert(res.message);
        requestUserList();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container>
      <Account>{email}</Account>
      <Text>회원 정보를 삭제 하시겠습니까?</Text>
      <AlertText>삭제된 정보는 복구할 수 없습니다.</AlertText>
      <BtnContainer>
        <Button color="error" variant="contained" onClick={requestDeleteUserInfo}>
          삭제
        </Button>
        <Button color="inherit" variant="contained" onClick={() => setModal(false)}>
          취소
        </Button>
      </BtnContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 180px;
  padding: 0 20px;
`;

const Account = styled.div`
  font-size: 20px;
`;

const Text = styled.div`
  font-size: 16px;
`;

const AlertText = styled.div`
  font-size: 16px;
  color: "red";
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 150px;
  margin-top: 20px;
`;

export default AccountDelete;
