import React from "react";
import styled from "@emotion/styled";

import { deleteCryptoLiveRankRewardDelete } from "@/api/cryptoApi";

import { Button } from "@mui/material";

type RankRewardDeleteProps = {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  arrInfoId: number;
  requestCryptoLiveRankReward: () => void;
};

const RankRewardDelete = ({ setModal, arrInfoId, requestCryptoLiveRankReward }: RankRewardDeleteProps) => {
  const requestCryptoLiveRankRewardDelete = async () => {
    try {
      const res = await deleteCryptoLiveRankRewardDelete({ arr_info_id: arrInfoId });

      if (res.status === 200) {
        setModal(false);
        requestCryptoLiveRankReward();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container>
      <Text>그룹 정보를 삭제 하시겠습니까?</Text>
      <BtnContainer>
        <Button color="error" variant="contained" onClick={requestCryptoLiveRankRewardDelete}>
          삭제
        </Button>
        <Button color="inherit" variant="contained" onClick={() => setModal(false)}>
          취소
        </Button>
      </BtnContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;

const Text = styled.div`
  font-size: 16px;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 150px;
  margin: 20px 0;
`;

export default RankRewardDelete;
