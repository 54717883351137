import { useRef, useState } from "react";
import styled from "@emotion/styled";

import { postImgProfileAdd } from "@/api/api";
import { postUserAdd } from "@/api/accountApi";

import RegisterRowWrapper from "@/components/table/RegisterRowWrapper";
import Select1 from "@/components/Select1";
import Title1 from "@/components/Title1";
import ImgUpload from "@/components/ImgUpload";
import LayoutContainer from "@/components/LayoutContainer";

import { Button, TextField, Typography } from "@mui/material";

import { GENDER_SELECT_DATA, LANGUAGE_SELECT_DATA } from "@/pages/account/accountConst";
import { validEmail } from "@/utils/validation";
import { useNavigate } from "react-router-dom";

const AccountRegister = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [nickName, setNickName] = useState("");
  const [password, setPassword] = useState("");
  const [pwCheck, setPwCheck] = useState("");
  const [handy, setHandy] = useState(0);
  const [language, setLanguage] = useState("KR");
  const [gender, setGender] = useState("S");
  const [profileUrl, setProfileUrl] = useState<any>(null);
  const [profileForm, setProfileForm] = useState<any>(null);

  const requestUserAdd = async () => {
    if (!email) return alert("이메일을 입력하세요");
    if (!validEmail(email)) return alert("이메일 형식을 확인하세요");
    if (!password || !pwCheck) return alert("비밀번호를 입력하세요");
    if (password !== pwCheck) return alert("비밀번호와 비밀번호 확인이 틀립니다");

    try {
      let profileUrl = null;
      if (profileForm) {
        const profileImgUrl = await postImgProfileAdd(profileForm);
        if (profileImgUrl.status !== 200) {
          return alert("배너 이미지 저장에 실패 했습니다");
        } else {
          profileUrl = profileImgUrl.data.img_path;
        }
      }

      const res = await postUserAdd({
        email,
        img_path: profileUrl,
        name: nickName,
        password,
        gender,
        handy,
        country: language,
        lang: language,
      });

      if (res.status === 200) {
        alert(res.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <LayoutContainer>
      <Container>
        <Title1 text="회원 등록" />
        <RegisterRowWrapper title="ID(Email)">
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="아이디를 입력하세요"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="유저 프로필 이미지">
          <ImgUpload
            url={profileUrl}
            setUrl={setProfileUrl}
            setForm={setProfileForm}
            type="normal"
            name="profile_image"
            width={100}
            height={100}
          />
          <TextContainer>
            <Typography>🔥 필수 등록은 아닙니다</Typography>
            <Typography>🔥 5mb 이하로 등록해야 됩니다</Typography>
          </TextContainer>
        </RegisterRowWrapper>

        <RegisterRowWrapper title="닉네임">
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="닉네임을 입력하세요"
            value={nickName}
            onChange={(e) => setNickName(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="비밀번호">
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            type={"password"}
            placeholder="비밀번호를 입력하세요"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="비밀번호 확인">
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            type={"password"}
            placeholder="비밀번호를 입력하세요"
            value={pwCheck}
            onChange={(e) => setPwCheck(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="핸디 수량">
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="수량을 입력하세요"
            value={handy}
            onChange={(e) => {
              if (isNaN(Number(e.target.value))) return;
              setHandy(Number(e.target.value));
            }}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="사용언어">
          <Select1
            selectData={LANGUAGE_SELECT_DATA}
            value={language}
            onChange={(selectValue) => setLanguage(selectValue)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="성별">
          <Select1 selectData={GENDER_SELECT_DATA} value={gender} onChange={(selectValue) => setGender(selectValue)} />
        </RegisterRowWrapper>

        <BtnContainer>
          <Button variant="contained" sx={{ width: "150px" }} onClick={requestUserAdd}>
            저장
          </Button>
          <Button variant="outlined" sx={{ width: "150px" }} onClick={() => navigate("/account")}>
            취소
          </Button>
        </BtnContainer>
      </Container>
    </LayoutContainer>
  );
};

const Container = styled.div``;

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
`;

const Img = styled.img`
  width: 320px;
  height: 72px;
  object-fit: contain;
`;

const ImgContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

// const ImgUpload = styled.input`
//   display: none;
// `;

// const UploadBtn = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   text-align: center;
//   width: 100px;
//   height: 100px;
//   cursor: pointer;
//   white-space: pre-wrap;
// `;

const TextContainer = styled.div`
  margin-top: 10px;
`;

export default AccountRegister;
