import { postManagementFeedbackInfo } from "@/api/managementApi";
import RegisterRowWrapper from "@/components/table/RegisterRowWrapper";
import { unescapeHtml } from "@/utils/format";
import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

type FeedbackDetailProps = {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  idx: number;
  requestManagementFeedbackList: () => void;
};

const FeedbackDetail = ({ setModal, idx, requestManagementFeedbackList }: FeedbackDetailProps) => {
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [feedbackType, setFeedbackType] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    requestManagementFeedbackInfo();

    return () => {
      requestManagementFeedbackList();
    };
  }, []);

  const requestManagementFeedbackInfo = async () => {
    try {
      const res = await postManagementFeedbackInfo({ idx });
      const { title, email, feedback_type, content } = res.data[0];

      setTitle(title);
      setEmail(email);
      setFeedbackType(feedback_type);
      setContent(content);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container>
      <RegisterRowWrapper title="제목">
        <Typography sx={{ maxWidth: "400px", height: "40px", display: "flex", alignItems: "center" }}>
          {title}
        </Typography>
      </RegisterRowWrapper>

      <RegisterRowWrapper title="작성자">
        <Typography sx={{ maxWidth: "400px", height: "40px", display: "flex", alignItems: "center" }}>
          {email}
        </Typography>
      </RegisterRowWrapper>

      <RegisterRowWrapper title="피드백 종류">
        <Typography sx={{ maxWidth: "400px", height: "40px", display: "flex", alignItems: "center" }}>
          {feedbackType}
        </Typography>
      </RegisterRowWrapper>

      <RegisterRowWrapper title="내용">
        <Typography
          sx={{
            maxWidth: "400px",
            minHeight: "40px",
            maxHeight: "350px",
            overflowY: "auto",
            paddingTop: "9px",
            wordWrap: "normal",
            wordBreak: "break-all",
          }}
        >
          {unescapeHtml(content)}
        </Typography>
      </RegisterRowWrapper>
      <BtnContainer>
        <Button variant={"contained"} sx={{ width: "150px" }} color="inherit" onClick={() => setModal(false)}>
          닫기
        </Button>
      </BtnContainer>
    </Container>
  );
};

const Container = styled.div``;

const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`;

export default FeedbackDetail;
