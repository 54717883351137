import React from "react";
import styled from "@emotion/styled";

import { deleteCryptoLiveDelete, getCryptoLiveInfo } from "@/api/cryptoApi";

import { Button } from "@mui/material";
import { postImgBannerDelete, postImgSymbolDelete } from "@/api/api";

type DeleteCryptoProps = {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  cryptoInfoId: number;
  contentKrName: string;
  requestCryptoLiveList: () => void;
};

const DeleteCrypto = ({ setModal, cryptoInfoId, contentKrName, requestCryptoLiveList }: DeleteCryptoProps) => {
  const requestCryptoLiveDelete = async () => {
    try {
      const info = await getCryptoLiveInfo({ crypto_info_id: cryptoInfoId });
      const imgUrl = info.data[0].img;
      const symbolImgUrl = info.data[0].symbol_img;

      const res = await deleteCryptoLiveDelete({
        crypto_info_id: cryptoInfoId,
      });

      if (imgUrl) {
        await postImgBannerDelete({
          img_path: imgUrl,
        });
      }

      if (symbolImgUrl) {
        await postImgSymbolDelete({
          img_path: symbolImgUrl,
        });
      }

      setModal(false);
      alert(res.message);
      requestCryptoLiveList();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container>
      <Accent>{contentKrName}</Accent>
      <Text>컨텐츠를 삭제 하시겠습니까?</Text>
      <BtnContainer>
        <Button color="error" variant="contained" onClick={requestCryptoLiveDelete}>
          삭제
        </Button>
        <Button color="inherit" variant="contained" onClick={() => setModal(false)}>
          취소
        </Button>
      </BtnContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const Accent = styled.div`
  font-size: 20px;
`;

const Text = styled.div`
  font-size: 16px;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

export default DeleteCrypto;
