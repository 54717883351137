import styled from "@emotion/styled";

import PaginationComponent from "@mui/material/Pagination";

type PaginationType = {
  page: number;
  selectPage: (page: number) => void;
  totalPage: number;
};

const Pagination = ({ page, selectPage, totalPage }: PaginationType) => {
  return (
    <Container>
      <PaginationComponent
        count={totalPage}
        page={page}
        defaultPage={1}
        variant="outlined"
        shape="rounded"
        onChange={(e, page) => selectPage(page)}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`;

export default Pagination;
