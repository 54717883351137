import styled from '@emotion/styled';

type Title1Props = {
  text:string;
}

const Title1 = ({text}:Title1Props) => {
  return (
    <Container>
      <Title>
        {text}
      </Title>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom:10px;
  border-bottom: 2px solid black;
`

const Title = styled.div`
  font-size: 25px;
`

export default Title1;