import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { postImgBannerAdd, postImgBannerUpdate } from "@/api/api";
import { postManagementBannerInfo, postManagementBannerUpdate } from "@/api/managementApi";

import RegisterRowWrapper from "@/components/table/RegisterRowWrapper";
import Select1 from "@/components/Select1";
import LayoutContainer from "@/components/LayoutContainer";
import ManagementTabBox from "@/pages/management/components/ManagementTabBox";
import ImgUpload from "@/components/ImgUpload";

import { useLocation, useNavigate } from "react-router-dom";
import { Button, Checkbox, TextField, Typography } from "@mui/material";

import { BANNER_PAGE_SELECT_DATA } from "@/pages/management/managementConst";
import { convertUTCDateToLocalDate, makeImgServerUrl } from "@/utils/format";

const BannerEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = location.state;

  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [push, setPush] = useState(false);
  const [status, setStatus] = useState(false);
  const [sortNum, setSortNum] = useState(0);
  const [page, setPage] = useState("Main");

  const [krBannerUrl, setKrBannerUrl] = useState<any>();
  const [krBannerForm, setKrBannerForm] = useState<any>();
  const [krBannerOldUrl, setKrBannerOldUrl] = useState("");

  const [enBannerUrl, setEnBannerUrl] = useState<any>();
  const [enBannerForm, setEnBannerForm] = useState<any>();
  const [enBannerOldUrl, setEnBannerOldUrl] = useState("");

  useEffect(() => {
    requestManagementBannerInfo();
  }, []);

  const requestManagementBannerInfo = async () => {
    try {
      const res = await postManagementBannerInfo({ idx: id });

      const { title, push, page, start_date, end_date, sort_num, status, k_img, e_img } = res.data[0];

      setTitle(title);
      setPush(!!push);
      setPage(page);
      setStartDate(convertUTCDateToLocalDate(start_date).toISOString().substring(0, 16));
      setEndDate(convertUTCDateToLocalDate(end_date).toISOString().substring(0, 16));
      setSortNum(sort_num);
      setStatus(status);

      if (k_img) {
        setKrBannerUrl(makeImgServerUrl(k_img));
        setKrBannerOldUrl(k_img);
      }

      if (e_img) {
        setEnBannerUrl(makeImgServerUrl(e_img));
        setEnBannerOldUrl(e_img);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const requestManagementBannerUpdate = async () => {
    try {
      let krBannerUrl: any = krBannerOldUrl;
      if (krBannerForm) {
        if (krBannerUrl) {
          const krBannerRes = await postImgBannerUpdate(krBannerForm);
          krBannerUrl = krBannerRes.data.img;
        } else {
          const krBannerRes = await postImgBannerAdd(krBannerForm);
          krBannerUrl = krBannerRes.data.img;
        }
      }

      let enBannerUrl: any = enBannerOldUrl;
      if (enBannerForm) {
        if (enBannerUrl) {
          const enBannerRes = await postImgBannerUpdate(enBannerForm);
          enBannerUrl = enBannerRes.data.img;
        } else {
          const enBannerRes = await postImgBannerAdd(enBannerForm);
          enBannerUrl = enBannerRes.data.img;
        }
      }

      const res = await postManagementBannerUpdate({
        idx: id,
        title,
        start_date: startDate,
        end_date: endDate,
        push,
        status,
        sort_num: sortNum,
        page,
        k_img: krBannerUrl,
        e_img: enBannerUrl,
      });

      if (res.status === 200) {
        navigate("/management/banner");
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <LayoutContainer>
      <ManagementTabBox />

      <RegisterRowWrapper title="제목">
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder="관리자페이지에서 표시되는 제목입니다"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </RegisterRowWrapper>

      <RegisterRowWrapper title="시작일시">
        <TextField
          id="datetime-local"
          type="datetime-local"
          size="small"
          sx={{ width: 250 }}
          InputLabelProps={{
            shrink: true,
          }}
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
      </RegisterRowWrapper>

      <RegisterRowWrapper title="종료일시">
        <TextField
          id="datetime-local"
          type="datetime-local"
          size="small"
          sx={{ width: 250 }}
          InputLabelProps={{
            shrink: true,
          }}
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </RegisterRowWrapper>

      <RegisterRowWrapper title="푸시 알림 여부">
        <CheckboxContainer>
          <Checkbox checked={push} onChange={(e, checked) => setPush(checked)} />
          <Typography sx={{ marginLeft: "10px" }}>푸시 알림</Typography>
        </CheckboxContainer>
      </RegisterRowWrapper>

      <RegisterRowWrapper title="게시 여부">
        <CheckboxContainer>
          <Checkbox checked={status} onChange={(e, checked) => setStatus(checked)} />
          <Typography sx={{ marginLeft: "10px" }}>게시</Typography>
        </CheckboxContainer>
      </RegisterRowWrapper>

      <RegisterRowWrapper title="게시순서">
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder="게시 순서를 입력하세요"
          value={sortNum}
          onChange={(e) => {
            if (e.target.value && !Number(e.target.value)) return;
            setSortNum(Number(e.target.value));
          }}
        />
      </RegisterRowWrapper>

      <RegisterRowWrapper title="게시 위치">
        <Select1 selectData={BANNER_PAGE_SELECT_DATA} value={page} onChange={(page) => setPage(page)} />
      </RegisterRowWrapper>

      <RegisterRowWrapper title="국문 배너 이미지">
        <ImgUpload
          url={krBannerUrl}
          setUrl={setKrBannerUrl}
          setForm={setKrBannerForm}
          editUrl={krBannerOldUrl}
          type="editKr"
          name="banner_image"
          width={320}
          height={72}
        />

        <TextContainer>
          <Typography>🔥 40:9 비율의 이미지를 업로드 해주세요</Typography>
          <Typography>🔥 권장 해상도는 960 × 216 입니다</Typography>
        </TextContainer>
      </RegisterRowWrapper>

      <RegisterRowWrapper title="영문 배너 이미지">
        <ImgUpload
          url={enBannerUrl}
          setUrl={setEnBannerUrl}
          setForm={setEnBannerForm}
          editUrl={enBannerOldUrl}
          type="editEn"
          name="banner_image"
          width={320}
          height={72}
        />
        <TextContainer>
          <Typography>🔥 40:9 비율의 이미지를 업로드 해주세요</Typography>
          <Typography>🔥 권장 해상도는 960 × 216 입니다</Typography>
        </TextContainer>
      </RegisterRowWrapper>

      <BtnContainer>
        <Button variant="contained" sx={{ width: "150px" }} onClick={requestManagementBannerUpdate}>
          수정
        </Button>
        <Button variant="outlined" sx={{ width: "150px" }} onClick={() => navigate("/management/banner")}>
          취소
        </Button>
      </BtnContainer>
    </LayoutContainer>
  );
};

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
`;

const TextContainer = styled.div`
  margin-top: 10px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

export default BannerEdit;
