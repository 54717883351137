import styled from "@emotion/styled";
import { Paper } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

type WinnerCardProps = {
  userInfoId: number;
  email: string;
  highPrice: number;
  lowPrice: number;
};

const WinnerCard = ({ userInfoId, email, highPrice, lowPrice }: WinnerCardProps) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() =>
        navigate(`/account/highlowlog`, {
          state: {
            id: userInfoId,
            email,
          },
        })
      }
    >
      <Paper
        elevation={3}
        sx={{
          marginLeft: 1,
          marginBottom: 2,
          width: "300px",
          padding: "5px 20px",
          cursor: "pointer",
          ":hover": {
            backgroundColor: "#a3a8d9",
          },
        }}
      >
        <div>{email}</div>
        <div>{`High: ${highPrice.toFixed(2)} / Low: ${lowPrice.toFixed(2)}`}</div>
      </Paper>
    </div>
  );
};

export default WinnerCard;
