import { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import { postManagementWithdrawUpdateStatus } from "@/api/managementApi";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

type TooltipBoxProps = {
  version: number;
  idx: number;
  requestManagementWithdraw: () => void;
};

const TooltipBox = ({ version, idx, requestManagementWithdraw }: TooltipBoxProps) => {
  const list = useRef<any>();
  const [length, setLength] = useState(0);

  useEffect(() => {
    setLength(list.current.children.length);
  }, []);

  const requestManagementWithdrawUpdateStatus = async (status: string) => {
    try {
      const res = await postManagementWithdrawUpdateStatus({
        version,
        idx,
        status,
      });

      if (res.status === 200) {
        requestManagementWithdraw();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container length={length}>
      <List ref={list}>
        <ListItem onClick={() => requestManagementWithdrawUpdateStatus("승인")} sx={{ cursor: "pointer" }}>
          <ListItemText style={{ color: "green" }}>승인</ListItemText>
        </ListItem>

        <ListItem onClick={() => requestManagementWithdrawUpdateStatus("신청")} sx={{ cursor: "pointer" }}>
          <ListItemText>신청</ListItemText>
        </ListItem>

        <ListItem onClick={() => requestManagementWithdrawUpdateStatus("취소")} sx={{ cursor: "pointer" }}>
          <ListItemText>취소</ListItemText>
        </ListItem>

        <ListItem onClick={() => requestManagementWithdrawUpdateStatus("차단")} sx={{ cursor: "pointer" }}>
          <ListItemText style={{ color: "red" }}>차단</ListItemText>
        </ListItem>

        <ListItem onClick={() => requestManagementWithdrawUpdateStatus("기타")} sx={{ cursor: "pointer" }}>
          <ListItemText>기타</ListItemText>
        </ListItem>

        <ListItem onClick={() => requestManagementWithdrawUpdateStatus("완료")} sx={{ cursor: "pointer" }}>
          <ListItemText style={{ color: "blue" }}>완료</ListItemText>
        </ListItem>
      </List>
    </Container>
  );
};

const Container = styled.div`
  width: 120px;
  height: ${({ length }: { length: number }) => `${length * 48 + 16}px`};
  background-color: "#fff";
`;

export default TooltipBox;
