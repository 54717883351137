import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import LayoutContainer from "@/components/LayoutContainer";
import Title1 from "@/components/Title1";
import Table1 from "@/components/table/Table1";
import Pagination from "@/components/Pagination";

import { useLocation } from "react-router-dom";

import { Paper } from "@mui/material";

import { commas, convertUTCDateToLocalDate, convertYYYYMMDD_HHMMSS, makeImgServerUrl } from "@/utils/format";
import { getUserLogPoint } from "@/api/accountApi";
import { POINTLOG_PAGE_PER_CONTENT_COUNT, POINT_TABLE_HEAD } from "@/pages/account/accountConst";
import emptyImg from "@/assets/img/aic_menu_profile.png";

const AccountPointLog = () => {
  const location = useLocation();
  const { id, email, imgUrl, nickname } = location.state;

  const [page, setPage] = useState(1);
  const [totalCnt, setTotalCnt] = useState(0);
  const [pointList, setPointList] = useState([]);
  const [oldHandy, setOldHandy] = useState(0);
  const [oldPoint, setOldPoint] = useState(0);
  const [handy, setHandy] = useState(0);

  useEffect(() => {
    requestUserLogPoint();
  }, []);

  useEffect(() => {});

  const requestUserLogPoint = async () => {
    try {
      const res = await getUserLogPoint({ user_info_id: id, email, page });
      const { totalCnt, rows } = res.data.usdpList;
      const { v1_handy, v1_point, v2_handy } = res.data.pointInfo[0];

      setPointList(rows);
      setTotalCnt(totalCnt);
      setOldHandy(v1_handy ? v1_handy : 0);
      setOldPoint(v1_point ? v1_point : 0);
      setHandy(v2_handy ? v2_handy : 0);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSelectPage = (page: number) => {
    setPage(page);
  };

  return (
    <LayoutContainer>
      <Title1 text="HANDY 포인트 로그" />
      <UserInfo>
        <Img alt="user image" src={imgUrl ? makeImgServerUrl(imgUrl) : emptyImg} />
        <TextContainer>
          <div>{nickname}</div>
          <div>{email}</div>
        </TextContainer>
        <Paper sx={{ padding: "10px", marginX: "10px" }}>V2 보유 HANDY : {commas(Number(handy.toFixed(2)))}</Paper>
        <Paper sx={{ padding: "10px", marginX: "10px" }}>V1 보유 HANDY : {commas(Number(oldHandy.toFixed(2)))}</Paper>
        <Paper sx={{ padding: "10px", marginX: "10px" }}>V1 보유 포인트 : {commas(Number(oldPoint.toFixed(2)))}</Paper>
      </UserInfo>
      <Table1
        head={POINT_TABLE_HEAD}
        rows={pointList.map((data) => {
          const { idx, insert_date, user_amount, reward_type, content, use_case, reward, ip_addr } = data;

          return [
            idx,
            convertYYYYMMDD_HHMMSS(convertUTCDateToLocalDate(insert_date)),
            commas(user_amount),
            `${reward_type}`.toUpperCase(),
            content,
            `${use_case === 1 ? "-" : "+"} ${commas(reward)}`,
            ip_addr,
          ];
        })}
      />
      <Pagination
        page={page}
        selectPage={handleSelectPage}
        totalPage={Math.ceil(totalCnt / POINTLOG_PAGE_PER_CONTENT_COUNT)}
      />
    </LayoutContainer>
  );
};

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin: 20px;
`;

const Img = styled.img`
  width: 50px;
  height: 50px;
  background-color: #363746;
  border-radius: 50%;
  object-fit: cover;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 15px;
`;

export default AccountPointLog;
