import React, { SyntheticEvent } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";

const HighLowTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tabList = ["list", "register", "carry"];

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    navigate(`/highlow/${tabList[newValue]}`);
  };

  const currPath = location.pathname.split("/").pop();

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabList.indexOf(currPath!)} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="리스트" />
          <Tab label="신규등록" />
          <Tab label="회차정보" />
        </Tabs>
      </Box>
    </Box>
  );
};

export default HighLowTabs;
