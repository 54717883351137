import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { postHighLowCarryOverList } from "@/api/highLowApi";

import { HighLowCarryList } from "@/pages/highLow/highlowType";

import Dialog from "@/components/Dialog";
import LayoutContainer from "@/components/LayoutContainer";
import Pagination from "@/components/Pagination";
import Table1 from "@/components/table/Table1";
import HighLowTabs from "@/pages/highLow/components/HighLowTabs";
import WinnerDetail from "@/pages/highLow/components/WinnerDetail";

import { Skeleton } from "@mui/material";

import { HIGHLOW_CARRY_PAGE_PER_CONTENT_COUNT, HIGHLOW_CARRY_TABLE_HEAD } from "@/pages/highLow/highLowConst";
import { commas, convertUTCDateToLocalDate, convertYYYYMMDD_HHMMSS } from "@/utils/format";

const HighLowCarry = () => {
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [carryList, setCarryList] = useState<HighLowCarryList[]>([]);
  const [hclInfoId, setHclInfoId] = useState(0);
  const [highPrice, setHighPrice] = useState(0);
  const [lowPrice, setLowPrice] = useState(0);

  useEffect(() => {
    requestHighLowCarryOverList();
  }, []);

  const requestHighLowCarryOverList = async () => {
    try {
      const res = await postHighLowCarryOverList({ page });

      const { highlowList, totalCnt } = res.data;
      setCarryList(highlowList);
      setTotalCount(totalCnt);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <LayoutContainer>
      <HighLowTabs />
      {loading ? (
        <Container>
          {[1, 2, 3, 4, 5].map((_, idx) => (
            <Skeleton key={idx} variant="rectangular" height={60} sx={{ marginBottom: "2px" }} />
          ))}
        </Container>
      ) : (
        <Container>
          <Dialog open={modal} onClose={() => setModal(false)} title="당첨자 정보">
            <WinnerDetail setModal={setModal} hclInfoId={hclInfoId} highPrice={highPrice} lowPrice={lowPrice} />
          </Dialog>

          <Table1
            head={HIGHLOW_CARRY_TABLE_HEAD}
            rows={carryList.map((data) => {
              const {
                carry_over_cnt,
                hcl_info_id,
                high_price,
                low_price,
                play_date,
                player_cnt,
                reward,
                reward_type,
                winner_cnt,
                symbol,
              } = data;

              return [
                hcl_info_id,
                carry_over_cnt,
                symbol ? symbol : "코인항목",
                player_cnt,
                isNaN(winner_cnt) ? (
                  "-"
                ) : Number(winner_cnt) > 0 ? (
                  <EditBtn
                    onClick={() => {
                      setHclInfoId(hcl_info_id);
                      setHighPrice(high_price);
                      setLowPrice(low_price);
                      setModal(true);
                    }}
                  >
                    {winner_cnt}
                  </EditBtn>
                ) : (
                  0
                ),
                <div>
                  <div>{`High: ${commas(high_price)}`}</div>
                  <div>{`Low: ${commas(low_price)}`}</div>
                </div>,
                convertYYYYMMDD_HHMMSS(convertUTCDateToLocalDate(play_date)),
                `${reward} ${reward_type.toUpperCase()}`,
              ];
            })}
          />
          <Pagination
            page={page}
            selectPage={(page) => setPage(page)}
            totalPage={Math.ceil(totalCount / HIGHLOW_CARRY_PAGE_PER_CONTENT_COUNT)}
          />
        </Container>
      )}
    </LayoutContainer>
  );
};

const Container = styled.div`
  margin-top: 20px;
`;

const EditBtn = styled.div`
  color: #1976d2;
  cursor: pointer;
`;

export default HighLowCarry;
