import React, { useState } from "react";
import styled from "@emotion/styled";

import RegisterRowWrapper from "@/components/table/RegisterRowWrapper";

import { Button, TextField } from "@mui/material";
import { postCryptoLiveRankRewardAdd } from "@/api/cryptoApi";

type CryptoRewardRegisterProps = {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  requestCryptoLiveRankReward: () => void;
};

const CryptoRewardRegister = ({ setModal, requestCryptoLiveRankReward }: CryptoRewardRegisterProps) => {
  const [rankNumS, setRankNumS] = useState<number | null>(null);
  const [rankNumE, setRankNumE] = useState<number | null>(null);
  const [reward, setReward] = useState<number | null>(null);

  const requestCryptoLiveRankRewardAdd = async () => {
    try {
      if (!rankNumS) return alert("최소 순위를 입력하세요");
      if (!rankNumE) return alert("최대 순위를 입력하세요");
      if (!reward) return alert("보상 금액(1인)을 입력해주세요");

      const res = await postCryptoLiveRankRewardAdd({
        rank_num_s: rankNumS,
        rank_num_e: rankNumE,
        reward,
      });

      if (res.status === 200) {
        requestCryptoLiveRankReward();
        setModal(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container>
      <RegisterRowWrapper title="최소 순위">
        <TextField
          variant="outlined"
          sx={{ width: "300px" }}
          size="small"
          placeholder="최소 순위를 입력하세요"
          value={rankNumS}
          onChange={(e) => {
            if (!Number(e.target.value)) return;
            setRankNumS(Number(e.target.value));
          }}
        />
      </RegisterRowWrapper>

      <RegisterRowWrapper title="최대 순위">
        <TextField
          variant="outlined"
          sx={{ width: "300px" }}
          size="small"
          placeholder="최대 순위를 입력하세요"
          value={rankNumE}
          onChange={(e) => {
            if (!Number(e.target.value)) return;
            setRankNumE(Number(e.target.value));
          }}
        />
      </RegisterRowWrapper>

      <RegisterRowWrapper title="보상 금액(1인)">
        <TextField
          variant="outlined"
          sx={{ width: "300px" }}
          size="small"
          placeholder="보상 금액(1인)을 입력하세요"
          value={reward}
          onChange={(e) => {
            if (!Number(e.target.value)) return;
            setReward(Number(e.target.value));
          }}
        />
      </RegisterRowWrapper>

      <BtnContainer>
        <Button variant="contained" sx={{ width: "150px" }} onClick={requestCryptoLiveRankRewardAdd}>
          저장
        </Button>
        <Button variant="outlined" sx={{ width: "150px" }} onClick={() => setModal(false)}>
          취소
        </Button>
      </BtnContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 500px;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;

export default CryptoRewardRegister;
