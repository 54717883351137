import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { useNavigate } from "react-router-dom";
import { Container, Typography, TextField, Button } from "@material-ui/core";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { useDispatch } from "react-redux";
import { setUserName } from "@/app/slice/publicSlice";
import { postAdminLogin } from "@/api/adminApi";

const Signin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otpType, setOtpType] = useState("android");
  const [otp, setOtp] = useState("");
  const [isEmailError, setIsEmailError] = useState(false);
  const [isPwdError, setIsPwdError] = useState(false);
  const [isOtpError, setIsOtpError] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("device")) {
      setOtpType(localStorage.getItem("device") === "ios" ? "ios" : "android");
    }
  }, []);

  useEffect(() => {
    setIsEmailError(false);
    setIsPwdError(false);
    setIsOtpError(false);
  }, [email, password, otp]);

  const requestLogin = async () => {
    try {
      if (!email) return setIsEmailError(true);
      if (!password) return setIsPwdError(true);
      if (!otp) return setIsOtpError(true);

      const reqData: {
        email: string;
        password: string;
        otp: string;
        otpType: "ios" | "android";
      } = {
        email,
        password,
        otp,
        otpType: otpType === "ios" ? "ios" : "android",
      };

      const res = await postAdminLogin(reqData);

      if (res.status === 200) {
        sessionStorage.setItem("ACCESS_TOKEN", res.data.accessToken);
        sessionStorage.setItem("REFRESH_TOKEN", res.data.refreshToken);
        await dispatch(setUserName(email));
        return navigate("/home");
      }
    } catch (err: any) {
      return alert(err.response.data.message);
    }
  };

  const handleEnter = (e: any) => {
    if (e.key === "Enter") {
      requestLogin();
    }
  };

  return (
    <AlignContainer>
      <Container maxWidth="xs" fixed>
        <Typography style={{ textAlign: "center" }} variant="h3">
          맞춰볼래 v2 ---
        </Typography>

        <TextFieldWrapper>
          <TextField
            variant="outlined"
            margin="normal"
            label="email"
            autoFocus
            error={isEmailError}
            helperText={isEmailError ? "이메일을 입력하세요" : ""}
            fullWidth
            required
            autoComplete="off"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </TextFieldWrapper>

        <TextFieldWrapper>
          <TextField
            variant="outlined"
            margin="normal"
            label="password"
            type="password"
            error={isPwdError}
            helperText={isPwdError ? "비밀번호를 입력하세요" : ""}
            fullWidth
            required
            autoComplete="off"
            name="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </TextFieldWrapper>

        <FormControl fullWidth sx={{ marginTop: 2 }}>
          <InputLabel id="demo-simple-select-label">OTP Device</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={otpType}
            label="OTP Device"
            onChange={(e) => {
              setOtpType(e.target.value === "ios" ? "ios" : "android");
              localStorage.setItem("device", e.target.value === "ios" ? "ios" : "android");
            }}
          >
            <MenuItem value={"android"}>Android</MenuItem>
            <MenuItem value={"ios"}>IOS</MenuItem>
          </Select>
        </FormControl>

        <TextFieldWrapper>
          <TextField
            variant="outlined"
            margin="normal"
            label="otp"
            type="text"
            error={isOtpError}
            helperText={isOtpError ? "OTP를 입력하세요" : ""}
            fullWidth
            required
            autoComplete="off"
            name="otp"
            onChange={(e) => setOtp(e.target.value)}
            onKeyUp={handleEnter}
          />
        </TextFieldWrapper>
        <Button
          fullWidth
          variant="contained"
          style={{
            backgroundColor: "orange",
            color: "#fff",
            marginTop: 10,
            marginBottom: 20,
          }}
          size="large"
          onClick={requestLogin}
        >
          로그인
        </Button>
        <Button
          fullWidth
          variant="contained"
          size="large"
          style={{ backgroundColor: "orange", color: "#fff" }}
          onClick={() => navigate("/signup")}
        >
          회원 가입
        </Button>
      </Container>
    </AlignContainer>
  );
};

const AlignContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

const TextFieldWrapper = styled.div`
  height: 103px;
`;

export default Signin;
