import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { postManagementFeedbackList, postManagementFeedbackSearch } from "@/api/managementApi";

import Dialog from "@/components/Dialog";
import LayoutContainer from "@/components/LayoutContainer";
import Pagination from "@/components/Pagination";
import Search1 from "@/components/Search1";
import Select1 from "@/components/Select1";
import Table1 from "@/components/table/Table1";
import ManagementTabBox from "@/pages/management/components/ManagementTabBox";
import FeedbackDetail from "@/pages/management/feedback/FeedbackDetail";

import { FEEDBACK_PAGE_SELECT_DATA, FEEDBACK_TABLE_HEAD } from "@/pages/management/managementConst";
import { convertUTCDateToLocalDate, convertYYYYMMDD_HHMMSS, unescapeHtml } from "@/utils/format";
import { Skeleton } from "@mui/material";

const Feedback = () => {
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [detailModal, setDetailModal] = useState(false);
  const [detailId, setDetailId] = useState(0);
  const [feedbackList, setFeedbackList] = useState([]);
  const [filterData, setFilterData] = useState("title");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!keyword) {
      requestManagementFeedbackList();
    } else {
      requestManagementFeedbackSearch(page);
    }
  }, [page]);

  const requestManagementFeedbackList = async () => {
    try {
      const res = await postManagementFeedbackList({ page });

      const { feedbackList, totalCnt } = res.data;

      if (feedbackList && totalCnt) {
        setFeedbackList(res.data.feedbackList);
        setTotalCount(res.data.totalCnt);
      } else {
        alert("데이터가 없습니다");
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const requestManagementFeedbackSearch = async (page: number) => {
    setLoading(true);
    try {
      const res = await postManagementFeedbackSearch({
        category: filterData,
        keyword,
        page,
      });
      const { feedbackList, totalCnt } = res.data;

      if (feedbackList && totalCnt) {
        setFeedbackList(res.data.feedbackList);
        setTotalCount(res.data.totalCnt);
        setPage(1);
      } else {
        alert("데이터가 없습니다");
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <LayoutContainer>
      <Dialog open={detailModal} onClose={() => setDetailModal(false)} title="피드백 상세 내용">
        <FeedbackDetail
          setModal={setDetailModal}
          idx={detailId}
          requestManagementFeedbackList={requestManagementFeedbackList}
        />
      </Dialog>

      <ManagementTabBox />

      <TopBarContainer>
        <AlignContainer>
          <Select1
            selectData={FEEDBACK_PAGE_SELECT_DATA}
            value={filterData}
            onChange={(value) => setFilterData(value)}
          />
          <Search1
            onChange={(text) => setKeyword(text)}
            onClick={() => {
              if (keyword) {
                setPage(1);
                requestManagementFeedbackSearch(1);
              } else {
                setPage(1);
                requestManagementFeedbackList();
              }
            }}
          />
        </AlignContainer>
      </TopBarContainer>

      {loading ? (
        <TableContainer>
          {[1, 2, 3, 4, 5].map((_, idx) => (
            <Skeleton key={idx} variant="rectangular" height={60} sx={{ marginBottom: "2px" }} />
          ))}
        </TableContainer>
      ) : (
        <TableContainer>
          {feedbackList?.length > 0 && (
            <Table1
              head={FEEDBACK_TABLE_HEAD}
              rows={feedbackList.map((data) => {
                const { idx, title, feedback_type, content, insert_date, update_date, email, status } = data;

                return [
                  idx,
                  <ModalBtn
                    onClick={() => {
                      setDetailId(idx);
                      setDetailModal(true);
                    }}
                  >
                    {title}
                  </ModalBtn>,
                  feedback_type,
                  <ModalBtn
                    onClick={() => {
                      setDetailId(idx);
                      setDetailModal(true);
                    }}
                  >
                    {unescapeHtml(content)}
                  </ModalBtn>,
                  convertYYYYMMDD_HHMMSS(convertUTCDateToLocalDate(insert_date)),
                  convertYYYYMMDD_HHMMSS(convertUTCDateToLocalDate(update_date)),
                  email,
                  !!status ? "확인" : "미확인",
                ];
              })}
            />
          )}
          <Pagination page={page} selectPage={(page) => setPage(page)} totalPage={Math.ceil(totalCount / 50)} />
        </TableContainer>
      )}
    </LayoutContainer>
  );
};

const TopBarContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const AlignContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 440px;
`;

const TableContainer = styled.div`
  margin-top: 20px;
`;

const ModalBtn = styled.div`
  text-align: center;
  margin: 0 auto;
  max-width: 200px;
  color: #1976d2;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default Feedback;
