export const ACCOUNT_TABLE_HEAD = [
  "아이디",
  "번호",
  "닉네임",
  "가입일",
  "최근 접속일",
  "국가",
  "IP 주소",
  "초대 코드",
  "보유 핸디",
  "메뉴",
];

export const POINT_TABLE_HEAD = [
  "인덱스",
  "날짜/시간",
  "사용/획득 전 보유 수량",
  "포인트/핸디",
  "사용/획득",
  "사용/획득 수량",
  "IP 주소",
];

export const USDP_TABLE_HEAD = ["인덱스", "날짜/시간", "USDP", "사용/획득", "IP 주소"];

export const HIGHLOW_TABLE_HEAD = ["인덱스", "참여 날짜/시간", "예측 가격", "실제 가격", "결과", "IP 주소"];

export const ACCOUNT_PAGE_PER_CONTENT_COUNT = 50;

export const POINTLOG_PAGE_PER_CONTENT_COUNT = 50;

export const USDPLOG_PAGE_PER_CONTENT_COUNT = 50;

export const LANGUAGE_SELECT_DATA = [
  {
    value: "KR",
    displayValue: "한국어",
  },
  {
    value: "EN",
    displayValue: "영어",
  },
  {
    value: "JP",
    displayValue: "일본어",
  },
  {
    value: "CN",
    displayValue: "중국어(간체)",
  },
  {
    value: "TW",
    displayValue: "중국어(번체)",
  },
  {
    value: "ID",
    displayValue: "인도네시아어",
  },
  {
    value: "VN",
    displayValue: "베트남어",
  },
  {
    value: "PT",
    displayValue: "포르투갈어",
  },
  {
    value: "ES",
    displayValue: "스페인어",
  },
];

export const GENDER_SELECT_DATA = [
  { value: "S", displayValue: "비공개" },
  { value: "M", displayValue: "남자" },
  { value: "F", displayValue: "여자" },
];

export const CATEGORY_SELECT_DATA = [
  { value: "email", displayValue: "이메일" },
  { value: "name", displayValue: "닉네임" },
  { value: "phone", displayValue: "전화번호" },
];
