import { apost } from "@/configs/AxiosConfig";

type MangementNoticeList = {
  page: number;
};
/**
 * 공지사항 목록 조회
 */
export const postManagementNoticeList = (body: MangementNoticeList) => {
  return apost("/management/notice/list", body);
};

type MangementNoticeInfo = {
  idx: number;
};
/**
 * 공지사항 상세 조회
 */
export const postManagementNoticeInfo = (body: MangementNoticeInfo) => {
  return apost("/management/notice/info", body);
};

type ManagementNoticeAdd = {
  title: string;
  start_date: string;
  end_date: string;
  push: boolean;
  push_send: boolean;
  push_send_title: string;
  push_send_content: string;
  status: boolean;
  sort_num: number;
  k_title: string;
  k_content: string;
  e_title: string;
  e_content: string;
};
/**
 * 공지사항 등록
 */
export const postManagementNoticeAdd = (body: ManagementNoticeAdd) => {
  return apost("/management/notice/add", body);
};

type ManagementNoticeUpdate = {
  idx: number;
  title: string;
  start_date: string;
  end_date: string;
  push: boolean;
  push_send: boolean;
  push_send_title: string;
  push_send_content: string;
  status: boolean;
  sort_num: number;
  k_title: string;
  k_content: string;
  e_title: string;
  e_content: string;
};
/**
 * 공지사항 수정
 */
export const postManagementNoticeUpdate = (body: ManagementNoticeUpdate) => {
  return apost("/management/notice/update", body);
};

type ManagementNoticeDelete = {
  idx: number;
};
/**
 * 공지사항 삭제
 */
export const postManagementNoticeDelete = (body: ManagementNoticeDelete) => {
  return apost("/management/notice/delete", body);
};

type ManagementBannerList = {
  page: number;
};
/**
 * 배너 목록 조회
 */
export const postManagementBannerList = (body: ManagementBannerList) => {
  return apost("/management/banner/list", body);
};

type ManagementBannerInfo = {
  idx: number;
};
/**
 * 배너 상세 조회
 */
export const postManagementBannerInfo = (body: ManagementBannerInfo) => {
  return apost("/management/banner/info", body);
};

type ManagementBannerAdd = {
  title: string;
  start_date: string;
  end_date: string;
  push: boolean;
  status: boolean;
  sort_num: number;
  page: string;
  k_img: string;
  e_img: string;
};
/**
 * 배너 등록
 */
export const postManagementBannerAdd = (body: ManagementBannerAdd) => {
  return apost("/management/banner/add", body);
};

type ManagementBannerUpdate = {
  idx: number;
  title: string;
  start_date: string;
  end_date: string;
  push: boolean;
  status: boolean;
  sort_num: number;
  page: string;
  k_img: string;
  e_img: string;
};
/**
 * 배너 수정
 */
export const postManagementBannerUpdate = (body: ManagementBannerUpdate) => {
  return apost("/management/banner/update", body);
};

type ManagementBannerDelete = {
  idx: number;
};
/**
 * 배너 삭제
 */
export const postManagementBannerDelete = (body: ManagementBannerDelete) => {
  return apost("/management/banner/delete", body);
};

type ManagementFeedbackList = {
  page: number;
};
/**
 * 피드백 목록 조회
 */
export const postManagementFeedbackList = (body: ManagementFeedbackList) => {
  return apost("/management/Feedback/list", body);
};

type ManagementFeedbackInfo = {
  idx: number;
};
/**
 * 피드백 상세 조회
 */
export const postManagementFeedbackInfo = (body: ManagementFeedbackInfo) => {
  return apost("/management/Feedback/info", body);
};

type ManagementFeedbackSearch = {
  category: string;
  keyword: string;
  page: number;
};
/**
 * 피드백 상세 조회
 */
export const postManagementFeedbackSearch = (body: ManagementFeedbackSearch) => {
  return apost("/management/Feedback/search", body);
};

type ManagementTermsList = {
  page: number;
};
/**
 * 약관 목록 조회
 */
export const postManagementTermsList = (body: ManagementTermsList) => {
  return apost("/management/terms/list", body);
};

type ManagementTermsInfo = {
  idx: number;
};
/**
 * 약관 상세 조회
 */
export const getManagementTermsInfo = (body: ManagementTermsInfo) => {
  return apost("/management/terms/info", body);
};

type ManagementTermsAdd = {
  title: string;
  k_title: string;
  k_content: string;
  e_title: string;
  e_content: string;
};
/**
 * 약관 등록
 */
export const postManagementTermsAdd = (body: ManagementTermsAdd) => {
  return apost("/management/terms/add", body);
};

type ManagementTermsUpdate = {
  idx: number;
  title: string;
  k_title: string;
  k_content: string;
  e_title: string;
  e_content: string;
};
/**
 * 약관 수정
 */
export const postManagementTermsUpdate = (body: ManagementTermsUpdate) => {
  return apost("/management/terms/update", body);
};

type ManagementTermsDelete = {
  idx: number;
};
/**
 * 약관 삭제
 */
export const postManagementTermsDelete = (body: ManagementTermsDelete) => {
  return apost("/management/terms/delete", body);
};

type ManagementWithdrawV1List = {
  status: string;
  version: number;
  page: number;
  network_type: string;
  start_date: string | null;
  end_date: string | null;
};
/**
 * 출금 내역 목록 조회
 */
export const postManagementWithdrawList = (body: ManagementWithdrawV1List) => {
  return apost("/management/withdraw/list", body);
};

type ManagementWithdrawV1UpdateStatus = {
  version: number;
  idx: number;
  status: string;
};
/**
 * 출금 내역 진행 상태 수정
 */
export const postManagementWithdrawUpdateStatus = (body: ManagementWithdrawV1UpdateStatus) => {
  return apost("/management/withdraw/update/status", body);
};

type ManagementWithdrawV1UpdateMemo = {
  version: number;
  idx: number;
  memo: string;
};
/**
 * 출금 내역 메모 수정
 */
export const postManagementWithdrawUpdateMemo = (body: ManagementWithdrawV1UpdateMemo) => {
  return apost("/management/withdraw/update/memo", body);
};

type ManagementWithdrawV1Search = {
  version: number;
  category: string;
  keyword: string;
  page: number;
};
/**
 * 출금 내역 검색
 */
export const postManagementWithdrawSearch = (body: ManagementWithdrawV1Search) => {
  return apost("/management/withdraw/search", body);
};
