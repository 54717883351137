import styled from "@emotion/styled";

import { DashboardType } from "@/pages/home/homeType";

import { Paper } from "@mui/material";

import { commas } from "@/utils/format";

type DataBoxProps = {
  dashboardData: DashboardType;
};

const DataBox = ({ dashboardData }: DataBoxProps) => {
  const { today_register_cnt, total_register_cnt, total_reward_handy } = dashboardData;

  const dataComponent = (title: string, count: number) => (
    <Data>
      <Title>{title}</Title>
      <Count>{commas(count)}</Count>
    </Data>
  );

  return (
    <Container>
      <Paper elevation={3} sx={{ width: 150, height: 100 }}>
        {dataComponent("신규 가입자", today_register_cnt)}
      </Paper>
      <Paper elevation={3} sx={{ width: 150, height: 100 }}>
        {dataComponent("누적 가입자", total_register_cnt)}
      </Paper>
      <Paper elevation={3} sx={{ width: 150, height: 100 }}>
        {dataComponent("Handy 지급수량", total_reward_handy)}
      </Paper>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
`;

const Title = styled.div`
  text-align: "left";
  font-size: 18px;
`;

const Count = styled.div`
  text-align: "right";
  font-size: 22px;
  font-weight: bold;
`;

export default DataBox;
