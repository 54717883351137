import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { postManagementWithdrawList, postManagementWithdrawSearch } from "@/api/managementApi";

import Select1 from "@/components/Select1";
import Search1 from "@/components/Search1";
import LayoutContainer from "@/components/LayoutContainer";
import ManagementTabBox from "@/pages/management/components/ManagementTabBox";
import Pagination from "@/components/Pagination";
import EditMemo from "@/pages/management/withdraw/components/EditMemo";
import Tooltip1 from "@/components/Tooltip1";
import TooltipBox from "@/pages/management/withdraw/components/TooltipBox";
import Dialog from "@/components/Dialog";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Skeleton, TextField } from "@mui/material";

import {
  WITHDRAW_CATEGORY_SELECT_DATA,
  WITHDRAW_NETWORK_SELECT_DATA,
  WITHDRAW_STATUS_SELECT_DATA,
  WITHDRAW_TABLE_HEAD,
  WITHDRAW_VERSION_SELECT_DATA,
} from "@/pages/management/managementConst";
import { commas, convertUTCDateToLocalDate, convertYYYYMMDD_HHMMSS, maskingAddress } from "@/utils/format";
import DetailView from "@/pages/management/withdraw/components/DetailView";
import Table2 from "@/components/table/Table2";

const Withdraw = () => {
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [withdrawList, setWithdrawList] = useState([]);
  const [detailModal, setDetailModal] = useState(false);
  const [detailData, setDetailData] = useState({
    email: "",
    address: "",
    result: "",
    memo: "",
  });
  const [memoModal, setMemoModal] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState("A");
  const [network, setNetwork] = useState("A");
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [version, setVersion] = useState(1);
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(true);
  const [preRequest, setPreRequest] = useState("list"); // 수정 후 전에 요청한 api 로 요청하기 위한 데이터
  const [editId, setEditId] = useState<number | null>(null);
  const [editMemo, setEditMemo] = useState("");
  const [copyToast, setCopyToast] = useState(false);

  useEffect(() => {
    setPage(1);
    requestManagementWithdrawList();
  }, [version, network, status, startDate, endDate]);

  useEffect(() => {
    if (!keyword) {
      requestManagementWithdrawList();
    } else {
      requestManagementWithdrawSearch(page);
    }
  }, [page]);

  const requestManagementWithdrawList = async () => {
    try {
      const res = await postManagementWithdrawList({
        status,
        version,
        page,
        network_type: network,
        start_date: startDate,
        end_date: endDate,
      });

      const { withdrawList, totalCnt } = res.data;

      if (withdrawList && totalCnt) {
        setWithdrawList(res.data.withdrawList);
        setTotalCount(res.data.totalCnt);
        setPreRequest("list");
      } else {
        alert("데이터가 없습니다");
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setWithdrawList([]);
      setLoading(false);
    }
  };

  const requestManagementWithdrawSearch = async (_page?: number) => {
    setLoading(true);
    try {
      const res = await postManagementWithdrawSearch({
        version,
        category,
        keyword,
        page: _page ? _page : page,
      });

      setPreRequest("search");
      const { withdrawList, totalCnt } = res.data;
      if (res.status === 200) {
        if (withdrawList && totalCnt) {
          setWithdrawList(res.data.withdrawList);
          setTotalCount(res.data.totalCnt);
        } else {
          alert("데이터가 없습니다");
        }
        setPage(1);
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setWithdrawList([]);
      setLoading(false);
    }
  };

  return (
    <LayoutContainer>
      <Dialog open={memoModal} onClose={() => setMemoModal(false)} title="메모">
        {editId && (
          <EditMemo
            setModal={setMemoModal}
            idx={editId}
            editMemo={editMemo}
            version={version}
            requestManagementWithdraw={
              preRequest === "list" ? requestManagementWithdrawList : requestManagementWithdrawSearch
            }
          />
        )}
      </Dialog>

      <Dialog open={detailModal} onClose={() => setDetailModal(false)} title="상세 정보" size="md">
        <DetailView detailData={detailData} />
      </Dialog>

      <ManagementTabBox />
      <Container>
        <TopBarContainer>
          <FilterContainer>
            <Filter>
              <Select1
                selectData={WITHDRAW_STATUS_SELECT_DATA}
                value={status}
                onChange={(stat) => setStatus(stat)}
                label="Status"
              />
              <Select1
                selectData={WITHDRAW_NETWORK_SELECT_DATA}
                value={network}
                onChange={(net) => setNetwork(net)}
                label="Network"
              />
              <Select1
                selectData={WITHDRAW_VERSION_SELECT_DATA}
                value={version.toString()}
                onChange={(ver) => {
                  setVersion(Number(ver));
                }}
                label="Version"
              />
              <TextField
                id="date"
                type="date"
                sx={{ minWidth: "170px" }}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <div>-</div>
              <TextField
                id="date"
                type="date"
                sx={{ minWidth: "170px" }}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Filter>
            <Filter>
              <Select1
                selectData={WITHDRAW_CATEGORY_SELECT_DATA}
                value={category}
                onChange={(sel) => setCategory(sel)}
                label="Category"
              />
              <Search1
                onChange={(text) => setKeyword(text)}
                onClick={() => {
                  if (keyword) {
                    setPage(1);
                    requestManagementWithdrawSearch(1);
                  } else {
                    setPage(1);
                    requestManagementWithdrawList();
                  }
                }}
              />
            </Filter>
          </FilterContainer>
        </TopBarContainer>

        {loading ? (
          <TableContainer>
            {[1, 2, 3, 4, 5].map((_, idx) => (
              <Skeleton key={idx} variant="rectangular" height={60} sx={{ marginBottom: "2px" }} />
            ))}
          </TableContainer>
        ) : (
          <TableContainer>
            {withdrawList?.length > 0 && (
              <Table2
                head={WITHDRAW_TABLE_HEAD}
                rowColor={withdrawList.map((data) => {
                  const { status } = data;

                  switch (status) {
                    case "승인":
                      return "success";
                    case "신청":
                      return "primary";
                    case "취소":
                      return "cancel";
                    case "차단":
                      return "error";
                    case "기타":
                      return "warning";
                    default:
                      return "normal";
                  }
                })}
                rows={withdrawList.map((data) => {
                  const {
                    idx,
                    email,
                    phone,
                    amount,
                    fee,
                    user_take_amount,
                    network,
                    withdraw_addr,
                    result,
                    insert_date,
                    status,
                    memo,
                  } = data;
                  return [
                    idx,
                    <EditBtn
                      onClick={() => {
                        setDetailData({
                          email,
                          address: withdraw_addr,
                          result,
                          memo,
                        });
                        setDetailModal(true);
                      }}
                    >
                      {email}
                    </EditBtn>,
                    phone ? phone : "NULL",
                    commas(amount),
                    commas(fee),
                    user_take_amount >= 0 ? commas(user_take_amount) : <ErrorText>{user_take_amount}</ErrorText>,
                    network === "E" ? "Ethereum" : "Klaytn",
                    <EditBtn
                      onClick={() => {
                        navigator.clipboard.writeText(withdraw_addr);
                        setCopyToast(true);
                      }}
                    >
                      {maskingAddress(withdraw_addr)}
                    </EditBtn>,
                    maskingAddress(result === null ? "NULL" : result),
                    convertYYYYMMDD_HHMMSS(convertUTCDateToLocalDate(insert_date)),
                    <Tooltip1
                      content={
                        <TooltipBox
                          version={version}
                          idx={idx}
                          requestManagementWithdraw={
                            preRequest === "list" ? requestManagementWithdrawList : requestManagementWithdrawSearch
                          }
                        />
                      }
                    >
                      <EditBtn>{status}</EditBtn>
                    </Tooltip1>,

                    <EditBtn
                      onClick={() => {
                        setEditId(idx);
                        setEditMemo(memo === null ? "" : memo);
                        setMemoModal(true);
                      }}
                    >
                      <TextOverflow>{memo === null ? "NULL" : memo} </TextOverflow>
                    </EditBtn>,
                  ];
                })}
              />
            )}
            <Pagination page={page} selectPage={(value) => setPage(value)} totalPage={Math.ceil(totalCount / 50)} />
          </TableContainer>
        )}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={copyToast}
          autoHideDuration={1000}
          onClose={() => setCopyToast(false)}
        >
          <MuiAlert elevation={6} variant="filled" severity="success" sx={{ width: "100%" }}>
            복사됨
          </MuiAlert>
        </Snackbar>
      </Container>
    </LayoutContainer>
  );
};

const Container = styled.div``;

const TopBarContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Filter = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const TableContainer = styled.div`
  margin-top: 20px;
`;

const EditBtn = styled.div`
  color: #1976d2;
  cursor: pointer;
`;

const TextOverflow = styled.div`
  min-width: 100px;
  max-width: 200px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ErrorText = styled.div`
  color: red;
`;

export default Withdraw;
