import { apost } from "@/configs/AxiosConfig";

type HighLowList = {
  page: number;
};
/**
 * highlow 콘텐츠 목록 조회 api
 */
export const postHighLowList = (body: HighLowList) => {
  return apost("/highlow/list", body);
};

type HighLowInfo = {
  highlow_info_id: number;
};
/**
 * highlow 콘텐츠 상세 조회 api
 */
export const postHighLowInfo = (body: HighLowInfo) => {
  return apost("/highlow/info", body);
};

type HighLowAdd = {
  content_name: string;
  content_e_name: string;
  img: string;
  symbol_img: string;
  start_date: string;
  end_date: string;
  participants: number;
  reward_type: string;
  play_max_cnt: number;
  symbol: string;
  reward: number;
};
/**
 * highlow 콘텐츠 등록 api
 */
export const postHighLowAdd = (body: HighLowAdd) => {
  return apost("/highlow/add", body);
};

type HighLowUpdate = {
  highlow_info_id: number;
  content_name: string;
  content_e_name: string;
  img: string;
  symbol_img: string;
  start_date: string;
  end_date: string;
  participants: number;
  reward_type: string;
  play_max_cnt: number;
  symbol: string;
  reward: number;
};
/**
 * highlow 콘텐츠 수정 api
 */
export const postHighLowUpdate = (body: HighLowUpdate) => {
  return apost("/highlow/update", body);
};

type HighLowDelete = {
  highlow_info_id: number;
};
/**
 * highlow 콘텐츠 삭제 api
 */
export const postHighLowDelete = (body: HighLowDelete) => {
  return apost("/highlow/delete", body);
};

type HighLowCarryOverList = {
  page: number;
};
/**
 * highlow 회차 리스트 조회 api
 */
export const postHighLowCarryOverList = (body: HighLowCarryOverList) => {
  return apost("/highlow/carry_over/list", body);
};

type HighLowCarryOverRewardInfo = {
  hcl_info_id: number;
};
/**
 * highlow 당첨자 조회 api
 */
export const postHighLowCarryOverRewardInfo = (body: HighLowCarryOverRewardInfo) => {
  return apost("/highlow/carry_over/reward/info", body);
};
