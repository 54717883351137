import { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import { getUserInfo, postUserUpdate } from "@/api/accountApi";

import RegisterRowWrapper from "@/components/table/RegisterRowWrapper";
import Select1 from "@/components/Select1";
import Title1 from "@/components/Title1";
import LayoutContainer from "@/components/LayoutContainer";

import { Button, Checkbox, Paper, TextField, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { GENDER_SELECT_DATA, LANGUAGE_SELECT_DATA } from "@/pages/account/accountConst";
import { validEmail } from "@/utils/validation";
import { convertYYYYMMDD } from "@/utils/format";
import { postCryptoLiveCommentBlock } from "@/api/cryptoApi";

const AccountEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const imgUploader = useRef<any>(null);

  const { id } = location.state;

  const [profileImg, setProfileImg] = useState<any>(null);

  const [email, setEmail] = useState("");
  const [profileImgForm, setProfileImgForm] = useState<any>();
  const [nickName, setNickName] = useState("");
  const [userType, setUserType] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [handy, setHandy] = useState(0);
  const [language, setLanguage] = useState("KR");
  const [gender, setGender] = useState("S");
  const [banStatus, setBanStatus] = useState(false);
  const [banReason, setBanReason] = useState("");
  const [commentBlockStatus, setCommentBlockStatus] = useState(false);
  const [commentBlockReason, setCommentBlockReason] = useState("");
  const [joinDate, setJoinDate] = useState("");
  const [editDate, setEditDate] = useState("");
  const [phone, setPhone] = useState("NULL");

  useEffect(() => {
    requestUserInfo();
  }, []);

  const requestUserInfo = async () => {
    try {
      const res = await getUserInfo({ user_info_id: id });

      const {
        email,
        img_path,
        name,
        referral_code,
        handy,
        lang,
        gender,
        social,
        everlasting,
        content,
        comment_blocked,
        comment_content,
        insert_date,
        update_date,
        phone,
      } = res.data.userInfo[0];

      setEmail(email);
      setProfileImg(img_path);
      setNickName(name);
      setUserType(social.toUpperCase());
      setReferralCode(referral_code);
      setHandy(handy);
      setLanguage(lang);
      setGender(gender);
      setBanStatus(!!everlasting);
      setBanReason(content);
      setCommentBlockStatus(!!comment_blocked);
      setCommentBlockReason(comment_content);
      setJoinDate(insert_date ? convertYYYYMMDD(insert_date) : "NULL");
      setEditDate(update_date ? convertYYYYMMDD(update_date) : "NULL");
      setPhone(phone ? phone : "NULL");
    } catch (err) {
      console.error(err);
    }
  };

  const requestUserUpdate = async () => {
    if (!email) return alert("이메일을 입력하세요");
    if (!validEmail(email)) return alert("이메일 형식을 확인하세요");
    try {
      const res = await postUserUpdate({
        user_info_id: id,
        email,
        img_path: profileImg,
        name: nickName,
        handy,
        lang: language,
        gender,
        everlasting: banStatus,
        content: banReason,
      });

      await postCryptoLiveCommentBlock({
        user_info_id: id,
        comment_blocked: commentBlockStatus ? 1 : 0,
        comment_content: commentBlockReason,
      });

      if (res.status === 200) {
        return navigate("/account");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleImgUpload = (e: any) => {
    const img = e.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(img);
    reader.onloadend = () => {
      setProfileImg(reader.result);
    };

    const formData = new FormData();

    formData.append("image", img);
    formData.append("name", "test");

    setProfileImgForm(formData);
  };

  return (
    <LayoutContainer>
      <Container>
        <Title1 text="회원 수정" />
        <RegisterRowWrapper title="ID(Email)">
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="아이디를 입력하세요"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="전화번호">
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              height: 40,
              fontSize: 18,
            }}
          >
            {phone}
          </Typography>
        </RegisterRowWrapper>

        <RegisterRowWrapper title="유저 프로필 이미지">
          {profileImg ? (
            <Img src={profileImg} alt="profile image" />
          ) : (
            <Paper sx={{ width: "100px", height: "100px" }}>
              <UploadBtn
                onClick={() => {
                  imgUploader.current.click();
                }}
              >
                {"이미지를\n등록하세요"}
              </UploadBtn>
            </Paper>
          )}
          <TextContainer>
            <Typography>🔥 필수 등록은 아닙니다</Typography>
            <Typography>🔥 5mb 이하로 등록해야 됩니다</Typography>
          </TextContainer>
          <ImgUpload
            type="file"
            ref={imgUploader}
            accept="image/*"
            name="profile_img"
            onChange={(e) => handleImgUpload(e)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="닉네임">
          <TextField
            variant="outlined"
            autoComplete="off"
            fullWidth
            size="small"
            placeholder="닉네임을 입력하세요"
            value={nickName}
            onChange={(e) => setNickName(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="회원 유형">
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              height: 40,
              fontSize: 18,
            }}
          >
            {userType}
          </Typography>
        </RegisterRowWrapper>

        <RegisterRowWrapper title="Referral Code">
          <TextField
            variant="outlined"
            autoComplete="off"
            fullWidth
            size="small"
            placeholder="추천인 코드를 입력하세요"
            value={referralCode}
            onChange={(e) => setReferralCode(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="핸디 수량">
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="수량을 입력하세요"
            value={handy}
            onChange={(e) => {
              if (isNaN(Number(e.target.value))) return;
              setHandy(Number(e.target.value));
            }}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="사용언어">
          <Select1
            selectData={LANGUAGE_SELECT_DATA}
            value={language}
            onChange={(selectValue) => setLanguage(selectValue)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="성별">
          <Select1 selectData={GENDER_SELECT_DATA} value={gender} onChange={(selectValue) => setGender(selectValue)} />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="계정 정지 상태">
          <AlignHorizontal>
            <Typography
              sx={{
                marginRight: 1,
                fontSize: 18,
                lineHeight: 0,
              }}
            >
              예
            </Typography>
            <Checkbox checked={banStatus} onChange={(e) => setBanStatus(e.target.checked)} />

            <Typography
              sx={{
                marginLeft: 3,
                marginRight: 1,
                fontSize: 18,
                lineHeight: 0,
              }}
            >
              아니오
            </Typography>
            <Checkbox checked={!banStatus} onChange={(e) => setBanStatus(!e.target.checked)} />
          </AlignHorizontal>
        </RegisterRowWrapper>

        <RegisterRowWrapper title="계정 정지 사유">
          <TextField
            variant="outlined"
            autoComplete="off"
            fullWidth
            size="small"
            placeholder="계정 정지 사유를 입력하세요"
            value={banReason}
            onChange={(e) => setBanReason(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="댓글 차단 상태">
          <AlignHorizontal>
            <Typography
              sx={{
                marginRight: 1,
                fontSize: 18,
                lineHeight: 0,
              }}
            >
              예
            </Typography>
            <Checkbox checked={commentBlockStatus} onChange={(e) => setCommentBlockStatus(e.target.checked)} />

            <Typography
              sx={{
                marginLeft: 3,
                marginRight: 1,
                fontSize: 18,
                lineHeight: 0,
              }}
            >
              아니오
            </Typography>
            <Checkbox checked={!commentBlockStatus} onChange={(e) => setCommentBlockStatus(!e.target.checked)} />
          </AlignHorizontal>
        </RegisterRowWrapper>

        <RegisterRowWrapper title="댓글 차단 사유">
          <TextField
            variant="outlined"
            autoComplete="off"
            fullWidth
            size="small"
            placeholder="댓글 차단 사유를 입력하세요"
            value={commentBlockReason}
            onChange={(e) => setCommentBlockReason(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="회원 가입일">
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              height: 40,
              fontSize: 18,
            }}
          >
            {joinDate}
          </Typography>
        </RegisterRowWrapper>

        <RegisterRowWrapper title="회원 수정일">
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              height: 40,
              fontSize: 18,
            }}
          >
            {editDate}
          </Typography>
        </RegisterRowWrapper>

        <BtnContainer>
          <Button variant="contained" sx={{ width: "150px" }} onClick={requestUserUpdate}>
            저장
          </Button>
          <Button variant="outlined" sx={{ width: "150px" }} onClick={() => navigate("/account")}>
            취소
          </Button>
        </BtnContainer>
      </Container>
    </LayoutContainer>
  );
};

const Container = styled.div``;

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
`;

const Img = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 50%;
`;

const ImgUpload = styled.input`
  display: none;
`;

const UploadBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100px;
  height: 100px;
  cursor: pointer;
  white-space: pre-wrap;
`;

const TextContainer = styled.div`
  margin-top: 10px;
`;

const AlignHorizontal = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

export default AccountEdit;
