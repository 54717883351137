import { postManagementNoticeDelete } from "@/api/managementApi";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import React from "react";

type NoticeDeleteProps = {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  idx: number;
  title: string;
  requestManagementNoticeList: () => void;
};

const NoticeDelete = ({ setModal, idx, title, requestManagementNoticeList }: NoticeDeleteProps) => {
  const requestManagementNoticeDelete = async () => {
    try {
      const res = await postManagementNoticeDelete({ idx });

      if (res.status === 200) {
        setModal(false);
        requestManagementNoticeList();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container>
      <Text>{title}</Text>
      <Text>공지 사항을 삭제 하시겠습니까?</Text>
      <BtnContainer>
        <Button color="error" variant="contained" onClick={requestManagementNoticeDelete}>
          삭제
        </Button>
        <Button color="inherit" variant="contained" onClick={() => setModal(false)}>
          취소
        </Button>
      </BtnContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;

const Text = styled.div`
  font-size: 16px;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 150px;
  margin: 20px 0;
`;

export default NoticeDelete;
