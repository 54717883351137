import React, { SyntheticEvent } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";

const CryptoTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tabList = ["list", "register", "rewardList", "rank", "commentList"];

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    navigate(`/cryptolive/${tabList[newValue]}`);
  };

  const currPath = location.pathname.split("/").pop();

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabList.indexOf(currPath!)} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="리스트" />
          <Tab label="신규등록" />
          <Tab label="순위표 설정" />
          <Tab label="랭킹" />
          <Tab label="댓글" />
        </Tabs>
      </Box>
    </Box>
  );
};

export default CryptoTabs;
