import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { postAdminApprove } from "@/api/adminApi";

import Select1 from "@/components/Select1";

import { Button } from "@mui/material";

type AdminApproveProps = {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  id: number;
  email: string;
  gradeList: any;
  requestAdminList: () => void;
};

const AdminApprove = ({ setModal, id, email, gradeList, requestAdminList }: AdminApproveProps) => {
  const [gradeCode, setGradeCode] = useState("");

  useEffect(() => {
    const initialCode = gradeList[gradeList.length - 1].value;
    setGradeCode(initialCode);
  }, []);

  const requestApprove = async () => {
    try {
      const res = await postAdminApprove({ admin_info_id: id, grade_code: gradeCode });

      if (res.status === 200) {
        setModal(false);
        requestAdminList();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container>
      <Text>{email}</Text>
      <Text>관리자 등급을 선택하고 승인해주세요</Text>
      <Select1 value={gradeCode} onChange={(value) => setGradeCode(value)} selectData={gradeList} />
      <BtnContainer>
        <Button color="primary" variant="contained" onClick={requestApprove}>
          승인
        </Button>
        <Button color="inherit" variant="contained" onClick={() => setModal(false)}>
          취소
        </Button>
      </BtnContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;

const Text = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 150px;
  margin: 20px 0;
`;

export default AdminApprove;
