import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { postAdminRegister } from "@/api/adminApi";

import { makeStyles, Container, Typography, TextField, Button } from "@material-ui/core";

import { useNavigate } from "react-router-dom";

const Signup = () => {
  const { heading } = useStyles();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");

  const [isNameError, setIsNameError] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [isPwdError, setIsPwdError] = useState(false);
  const [imgUrl, setImgUrl] = useState("");

  useEffect(() => {
    setIsNameError(false);
    setIsEmailError(false);
    setIsPwdError(false);
  }, [name, email, password]);

  const requestRegister = async () => {
    try {
      if (!name) return setIsNameError(true);
      if (!email) return setIsEmailError(true);
      if (!password) return setIsPwdError(true);

      const reqData = { name, email, password };

      const { status, data, message } = await postAdminRegister(reqData);

      if (status === 200) setImgUrl(data.imageData);
      else alert(message);
    } catch (err: any) {
      console.log(err.response.data.message);
    }
  };

  return (
    <AlignContainer>
      <Container maxWidth="sm" fixed>
        {imgUrl ? (
          <>
            <img src={imgUrl} />
            <p>가입이 완료 되었습니다.</p>
            <p>Google OTP 앱을 통해 해당 QR 코드를 찍으신후 등록해주세요.</p>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => navigate("/")}
              style={{ marginTop: 20 }}
            >
              완료
            </Button>
          </>
        ) : (
          <>
            <Typography className={heading} variant="h3">
              회원 가입
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              label="name"
              autoFocus
              error={isNameError}
              helperText={isNameError ? "성함을 입력하세요" : ""}
              fullWidth
              required
              name="name"
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              label="email"
              autoFocus
              error={isEmailError}
              helperText={isEmailError ? "이메일을 입력하세요" : ""}
              fullWidth
              required
              name="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              label="password"
              type="password"
              error={isPwdError}
              helperText={isPwdError ? "비밀번호를 입력하세요" : ""}
              fullWidth
              required
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => requestRegister()}
              style={{ marginTop: 20 }}
            >
              다음
            </Button>
          </>
        )}
      </Container>
    </AlignContainer>
  );
};

const AlignContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: "center",
    margin: theme.spacing(1, 0, 4),
  },
}));

export default Signup;
