import React, { Fragment, useEffect, useState } from "react";
import styled from "@emotion/styled";

import { getCryptoLiveRankReward } from "@/api/cryptoApi";

import { RewardEditData } from "@/pages/crypto/cryptoType";

import Dialog from "@/components/Dialog";
import LayoutContainer from "@/components/LayoutContainer";
import Table1 from "@/components/table/Table1";
import RewardEdit from "@/pages/crypto/components/RewardEdit";
import RankRewardRegister from "@/pages/crypto/CryptoRewardRegister";
import RewardDelete from "@/pages/crypto/components/RewardDelete";
import CryptoTabs from "@/pages/crypto/components/CryptoTabs";

import { Button, Skeleton } from "@mui/material";

import { RANK_REWARD_TABLE_HEAD } from "@/pages/crypto/cryptoConst";

const CryptoRewardList = () => {
  const [registerModal, setRegisterModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [rankRewardList, setRankRewardList] = useState([]);
  const [editData, setEditData] = useState<RewardEditData>({
    arr_info_id: null,
    rank_num_s: null,
    rank_num_e: null,
    reward: null,
  });
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    requestCryptoLiveRankReward();
  }, []);

  const requestCryptoLiveRankReward = async () => {
    try {
      const res = await getCryptoLiveRankReward();
      if (res.status === 200) {
        setRankRewardList(res.data);
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <LayoutContainer>
      <Dialog open={registerModal} onClose={() => setRegisterModal(false)} title="리워드 그룹 추가">
        <RankRewardRegister setModal={setRegisterModal} requestCryptoLiveRankReward={requestCryptoLiveRankReward} />
      </Dialog>

      <Dialog open={editModal} onClose={() => setEditModal(false)} title="리워드 그룹 수정">
        <RewardEdit
          editData={editData}
          setEditData={setEditData}
          setModal={setEditModal}
          requestCryptoLiveRankReward={requestCryptoLiveRankReward}
        />
      </Dialog>

      {deleteId && (
        <Dialog open={deleteModal} onClose={() => setDeleteModal(false)} title="리워드 그룹 삭제">
          <RewardDelete
            setModal={setDeleteModal}
            arrInfoId={deleteId}
            requestCryptoLiveRankReward={requestCryptoLiveRankReward}
          />
        </Dialog>
      )}

      <CryptoTabs />

      <Container>
        {loading ? (
          <Fragment>
            {[1, 2, 3, 4, 5].map((_, idx) => (
              <Skeleton key={idx} variant="rectangular" height={60} sx={{ marginBottom: "2px" }} />
            ))}
          </Fragment>
        ) : (
          <Table1
            head={RANK_REWARD_TABLE_HEAD}
            rows={rankRewardList.map((data) => {
              const { arr_info_id, rank_num_e, rank_num_s, reward } = data;

              return [
                arr_info_id,
                rank_num_s,
                rank_num_e,
                reward,
                <BtnContainer>
                  <Button
                    variant={"contained"}
                    color="secondary"
                    onClick={() => {
                      setEditData({
                        arr_info_id,
                        rank_num_s,
                        rank_num_e,
                        reward,
                      });
                      setEditModal(true);
                    }}
                  >
                    수정
                  </Button>
                  <Button
                    variant={"contained"}
                    color="error"
                    onClick={() => {
                      setDeleteId(arr_info_id);
                      setDeleteModal(true);
                    }}
                  >
                    삭제
                  </Button>
                </BtnContainer>,
              ];
            })}
          />
        )}
        <BtnWrapper>
          <Button variant={"contained"} color="primary" onClick={() => setRegisterModal(true)}>
            그룹 추가
          </Button>
        </BtnWrapper>
      </Container>
    </LayoutContainer>
  );
};

const Container = styled.div`
  margin-top: 20px;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export default CryptoRewardList;
