import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { getCryptoLiveLankCrypto, getCryptoLiveLankCryptoHistory } from "@/api/cryptoApi";

import LayoutContainer from "@/components/LayoutContainer";
import Table1 from "@/components/table/Table1";
import CryptoTabs from "@/pages/crypto/components/CryptoTabs";

import { Button, Skeleton } from "@mui/material";

import { commas } from "@/utils/format";
import { RANK_TABLE_HEAD } from "@/pages/management/managementConst";

const CryptoRank = () => {
  const [isCurrent, setIsCurrent] = useState(true);
  const [loading, setLoading] = useState(true);
  const [rankList, setRankList] = useState([]);

  useEffect(() => {
    if (isCurrent) {
      requestCurrentRank();
    } else {
      requestCurrentRankHistory();
    }
  }, [isCurrent]);

  const requestCurrentRank = async () => {
    try {
      const res = await getCryptoLiveLankCrypto();

      if (res.data.rankList.length > 0) {
        setRankList(res.data.rankList);
      } else {
        alert("데이터가 없습니다.");
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const requestCurrentRankHistory = async () => {
    try {
      const res = await getCryptoLiveLankCryptoHistory();

      if (res.data.rankList.length > 0) {
        setRankList(res.data.rankList);
      } else {
        alert("데이터가 없습니다.");
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <LayoutContainer>
      <CryptoTabs />

      <TopBarContainer>
        <Button variant={isCurrent ? "outlined" : "contained"} onClick={() => setIsCurrent(false)}>
          이전 차수
        </Button>
        <Button variant={isCurrent ? "contained" : "outlined"} onClick={() => setIsCurrent(true)}>
          현재 차수
        </Button>
      </TopBarContainer>

      {loading ? (
        <TableContainer>
          {[1, 2, 3, 4, 5].map((_, idx) => (
            <Skeleton key={idx} variant="rectangular" height={60} sx={{ marginBottom: "2px" }} />
          ))}
        </TableContainer>
      ) : (
        <TableContainer>
          {rankList?.length > 0 && (
            <Table1
              head={RANK_TABLE_HEAD}
              rows={rankList.map((data) => {
                const { email, usdp, ranking } = data;

                return [ranking, email, commas(usdp)];
              })}
            />
          )}
        </TableContainer>
      )}
    </LayoutContainer>
  );
};

const TopBarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
`;

const TableContainer = styled.div`
  margin-top: 20px;
`;

export default CryptoRank;
