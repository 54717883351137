import { makeFormData } from "@/utils/format";
import styled from "@emotion/styled";
import { Button, Paper } from "@mui/material";
import React, { useRef } from "react";

type ImgUploadProps = {
  url: string | ArrayBuffer | null;
  setUrl: React.Dispatch<React.SetStateAction<string | ArrayBuffer | null>>;
  setForm: React.Dispatch<React.SetStateAction<FormData>>;
  width: number;
  height: number;
  type: "normal" | "edit" | "kr" | "en" | "editKr" | "editEn";
  name: "banner_image" | "symbol_image" | "profile_image";
  editUrl?: string;
};

const ImgUpload = ({ url, setUrl, setForm, width, height, type, name, editUrl }: ImgUploadProps) => {
  const ImgUploaderRef = useRef<any>(null);

  const handleImgUpload = async (e: any) => {
    const img = e.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(img);
    reader.onloadend = () => setUrl(reader.result);

    const formData = editUrl ? await makeFormData(name, img, type, editUrl) : await makeFormData(name, img, type);
    setForm(formData);
  };

  return (
    <div>
      {url ? (
        <ImgContainer>
          {typeof url === "string" && <Img src={url} alt={name} width={width} height={height} />}
          <Button
            variant="text"
            onClick={() => {
              setUrl(null);
              ImgUploaderRef.current.value = "";
            }}
          >
            취소
          </Button>
        </ImgContainer>
      ) : (
        <Paper
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: `${width}px`,
            height: `${height}px`,
          }}
        >
          <UploadBtn
            width={width}
            height={height}
            onClick={() => {
              ImgUploaderRef.current.click();
            }}
          >
            {"이미지를\n등록하세요"}
          </UploadBtn>
        </Paper>
      )}

      <ImgUploader
        type="file"
        ref={ImgUploaderRef}
        accept="image/jpg,image/png,image/jpeg"
        name={name}
        onChange={handleImgUpload}
      />
    </div>
  );
};

const Img = styled.img`
  object-fit: contain;
  ${({ width, height }: { width: number; height: number }) => `width: ${width}px; height:${height}px;`};
`;

const ImgContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const ImgUploader = styled.input`
  display: none;
`;

const UploadBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: pre-wrap;
  cursor: pointer;

  ${({ width, height }: { width: number; height: number }) => `width: ${width}px; height:${height}px;`};
`;

export default ImgUpload;
