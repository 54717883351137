import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
interface PublicState {
  userName: string;
}

// Define the initial state using that type
const initialState: PublicState = {
  userName: "",
};

export const publicSlice = createSlice({
  name: "public",
  initialState,
  reducers: {
    setUserName: (state, action: PayloadAction<string>) => {
      state.userName = action.payload;
    },
  },
});

export const { setUserName } = publicSlice.actions;

export default publicSlice.reducer;
