import { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import { postAdminInfo, postAdminUpdate } from "@/api/adminApi";

import RegisterRowWrapper from "@/components/table/RegisterRowWrapper";
import Select1 from "@/components/Select1";
import Title1 from "@/components/Title1";
import LayoutContainer from "@/components/LayoutContainer";

import { useLocation, useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";

import { ADMIN_APPROVE_SELECT_DATA } from "@/pages/admin/adminConst";
import { validEmail } from "@/utils/validation";

const AdminEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id, gradeList } = location.state;

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [gradeCode, setGradeCode] = useState("");
  const [password, setPassword] = useState<string | null>(null);
  const [pwConfirm, setPwConfirm] = useState<string | null>(null);
  const [status, setStatus] = useState("1");

  useEffect(() => {
    requestAdminInfo();
  }, []);

  const requestAdminInfo = async () => {
    try {
      const res = await postAdminInfo({ admin_info_id: id });

      const { email, name, grade_code, description, status } = res.data[0];

      setEmail(email);
      setName(name);
      setGradeCode(grade_code);
      setStatus(status);
    } catch (err) {
      console.error(err);
    }
  };

  const requestAdminUpdate = async () => {
    if (!email) return alert("이메일을 입력하세요");
    if (!validEmail(email)) return alert("이메일 형식을 확인하세요");
    if (password !== pwConfirm) return alert("페스워드가 동일하지 않습니다");
    if (!name) return alert("이름을 입력하세요");

    try {
      const res = await postAdminUpdate({
        admin_info_id: id,
        grade_code: gradeCode,
        name,
        password,
        status: Number(status),
      });

      if (res.status === 200) {
        return navigate("/admin");
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <LayoutContainer>
      <Container>
        <Title1 text="관리자 수정" />
        <RegisterRowWrapper title="ID(Email)">
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="이메일을 입력하세요"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="패스워드">
          <TextField
            variant="outlined"
            autoComplete="off"
            fullWidth
            type={"password"}
            size="small"
            placeholder="패스워드를 입력하세요"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="패스워드 확인">
          <TextField
            variant="outlined"
            autoComplete="off"
            type={"password"}
            fullWidth
            size="small"
            placeholder="패스워드를 입력하세요"
            value={pwConfirm}
            onChange={(e) => setPwConfirm(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="이름">
          <TextField
            variant="outlined"
            autoComplete="off"
            fullWidth
            size="small"
            placeholder="이름을 입력하세요"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="등급">
          <Select1 selectData={gradeList} value={gradeCode} onChange={(code) => setGradeCode(code)} />
        </RegisterRowWrapper>

        <RegisterRowWrapper title="승인 상태">
          <Select1 selectData={ADMIN_APPROVE_SELECT_DATA} value={status} onChange={(value) => setStatus(value)} />
        </RegisterRowWrapper>

        <BtnContainer>
          <Button variant="contained" sx={{ width: "150px" }} onClick={requestAdminUpdate}>
            저장
          </Button>
          <Button variant="outlined" sx={{ width: "150px" }} onClick={() => navigate("/admin")}>
            취소
          </Button>
        </BtnContainer>
      </Container>
    </LayoutContainer>
  );
};

const Container = styled.div``;

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
`;

const Img = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 50%;
`;

const ImgUpload = styled.input`
  display: none;
`;

const UploadBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100px;
  height: 100px;
  cursor: pointer;
  white-space: pre-wrap;
`;

const TextContainer = styled.div`
  margin-top: 10px;
`;

const AlignHorizontal = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

export default AdminEdit;
