import { Fragment, useEffect, useState } from "react";
import styled from "@emotion/styled";

import { getUserList, postUserSearch } from "@/api/accountApi";

import LayoutContainer from "@/components/LayoutContainer";
import Table1 from "@/components/table/Table1";
import Tooltip1 from "@/components/Tooltip1";
import TooltipBox from "@/pages/account/components/TooltipBox";
import Pagination from "@/components/Pagination";
import Dialog from "@/components/Dialog";
import AccountDelete from "@/pages/account/components/AccountDelete";
import Search1 from "@/components/Search1";
import Title1 from "@/components/Title1";

import Button from "@mui/material/Button";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";

import { ACCOUNT_PAGE_PER_CONTENT_COUNT, ACCOUNT_TABLE_HEAD, CATEGORY_SELECT_DATA } from "@/pages/account/accountConst";
import { commas } from "@/utils/format";
import Select1 from "@/components/Select1";
import { Skeleton } from "@mui/material";

const Account = () => {
  const navigate = useNavigate();

  const [deleteId, setDeleteId] = useState(0);
  const [deleteEmail, setDeleteEmail] = useState("");
  const [modal, setModal] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [accountList, setAccountList] = useState([]);
  const [category, setCategory] = useState("email");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!keyword) {
      requestUserList();
    } else {
      requestUserSearch(page);
    }
  }, [page]);

  const requestUserList = async () => {
    try {
      const res = await getUserList({ page });

      if (res.status === 200) {
        const { userList, totalCnt } = res.data;

        setAccountList(userList);
        setTotalCount(totalCnt);
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const requestUserSearch = async (page: number) => {
    try {
      const res = await postUserSearch({
        category,
        keyword,
        page,
      });

      if (res.status === 200) {
        const { userList, totalCnt } = res.data;

        setAccountList(userList);
        setTotalCount(totalCnt);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteModal = (userInfoId: number, email: string) => {
    setDeleteId(userInfoId);
    setDeleteEmail(email);
    setModal(true);
  };

  return (
    <LayoutContainer>
      <Container>
        <Title1 text="회원 정보" />
        <Dialog open={modal} onClose={() => setModal(false)} title="회원정보 삭제">
          <AccountDelete
            setModal={setModal}
            userInfoId={deleteId}
            email={deleteEmail}
            requestUserList={requestUserList}
          />
        </Dialog>
        <TopContainer>
          <Button variant={"contained"} onClick={() => navigate("/account/register")}>
            + 신규등록
          </Button>
          <SearchContainer>
            <Select1
              selectData={CATEGORY_SELECT_DATA}
              onChange={(selectValue) => setCategory(selectValue)}
              value={category}
            />
            <Search1
              onChange={(text) => setKeyword(text)}
              onClick={() => {
                if (keyword) {
                  setPage(1);
                  requestUserSearch(1);
                } else {
                  setPage(1);
                  requestUserList();
                }
              }}
            />
          </SearchContainer>
        </TopContainer>
        {loading ? (
          <Container>
            {[1, 2, 3, 4, 5].map((_, idx) => (
              <Skeleton key={idx} variant="rectangular" height={60} sx={{ marginBottom: "2px" }} />
            ))}
          </Container>
        ) : (
          <Fragment>
            <Table1
              head={ACCOUNT_TABLE_HEAD}
              rows={accountList.map((data) => {
                const {
                  user_info_id,
                  phone,
                  email,
                  name,
                  insert_date,
                  login_Date,
                  country,
                  ip_addr,
                  referral_code,
                  handy,
                  img_path,
                } = data;

                return [
                  email ? email : "NULL",
                  phone ? phone : "NULL",
                  name ? name : "NULL",
                  insert_date ? new Date(insert_date).toISOString().substring(0, 10) : "NULL",
                  login_Date ? new Date(login_Date).toISOString().substring(0, 10) : "NULL",
                  country ? country : "NULL",
                  ip_addr ? ip_addr : "NULL",
                  referral_code ? referral_code : "NULL",
                  commas(handy),
                  <Tooltip1
                    content={
                      <TooltipBox
                        userInfoId={user_info_id}
                        email={email}
                        imgUrl={img_path}
                        nickname={name}
                        handleDeleteModal={handleDeleteModal}
                      />
                    }
                  >
                    <SettingsIcon />
                  </Tooltip1>,
                ];
              })}
            />
            <Pagination
              page={page}
              totalPage={Math.ceil(totalCount / ACCOUNT_PAGE_PER_CONTENT_COUNT)}
              selectPage={(page) => setPage(page)}
            />
          </Fragment>
        )}
      </Container>
    </LayoutContainer>
  );
};

const Container = styled.div``;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 15px 0;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default Account;
