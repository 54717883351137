import React from "react";
import styled from "@emotion/styled";

import { TextField } from "@mui/material";
import { Button } from "@material-ui/core";

type Search1Props = {
  onChange: (text: string) => void;
  onClick: () => void;
};

const Search1 = ({ onChange, onClick }: Search1Props) => {
  return (
    <SearchContainer>
      <TextField
        id="outlined-basic"
        label="검색할 내용을 입력하세요"
        variant="outlined"
        size="small"
        onChange={(e) => onChange(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            onClick();
          }
        }}
      />
      <Button variant="contained" size="small" style={{ marginLeft: 10 }} onClick={onClick}>
        검색
      </Button>
    </SearchContainer>
  );
};

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default Search1;
