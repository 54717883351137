import { aget, apost } from "@/configs/AxiosConfig";

type CryptoLiveList = {
  page: number;
};
/**
crypto live 콘텐츠 목록 조회 api
 */
export const getCryptoLiveList = (body: CryptoLiveList) => {
  return apost("/crypto_live/list", body);
};

type CryptoLiveInfo = {
  crypto_info_id: number;
};
/**
crypto live 콘텐츠 상세 조회 api
 */
export const getCryptoLiveInfo = (body: CryptoLiveInfo) => {
  return apost("/crypto_live/info", body);
};

type CryptoLiveAdd = {
  content_name: string;
  content_e_name: string;
  img: string;
  symbol_img: string;
  start_date: string;
  end_date: string;
  participants: number;
  reward_type: string;
  carry_over_cnt: number;
  ads_cnt: number;
  ads_usdp: number;
  symbol: string;
  bat_time: number;
};
/**
crypto live 콘텐츠 등록 api
 */
export const postCryptoLiveAdd = (body: CryptoLiveAdd) => {
  return apost("/crypto_live/add", body);
};

type CryptoLiveUpdate = {
  crypto_info_id: number;
  content_name: string;
  content_e_name: string;
  img: string;
  symbol_img: string;
  start_date: string;
  end_date: string;
  participants: number;
  reward_type: string;
  carry_over_cnt: number;
  ads_cnt: number;
  ads_usdp: number;
  symbol: string;
  bat_time: number;
};
/**
crypto live 콘텐츠 수정 api
 */
export const patchCryptoLiveUpdate = (body: CryptoLiveUpdate) => {
  return apost("/crypto_live/update", body);
};

type CryptoLiveDelete = {
  crypto_info_id: number;
};
/**
crypto live 콘텐츠 삭제 api
 */
export const deleteCryptoLiveDelete = (body: CryptoLiveDelete) => {
  return apost("/crypto_live/delete", body);
};

export const getCryptoLiveRankReward = () => {
  return aget("/crypto_live/rank_reward");
};

type CryptoLiveRankRewardAdd = {
  rank_num_s: number;
  rank_num_e: number;
  reward: number;
};
/**
crypto live 랭킹 보상 그룹 추가 api
 */
export const postCryptoLiveRankRewardAdd = (body: CryptoLiveRankRewardAdd) => {
  return apost("/crypto_live/rank_reward/add", body);
};

type CryptoLiveRankRewardUpdate = {
  arr_info_id: number;
  rank_num_s: number;
  rank_num_e: number;
  reward: number;
};
/**
crypto live 랭킹 보상 그룹 수정 api
 */
export const patchCryptoLiveRankRewardUpdate = (body: CryptoLiveRankRewardUpdate) => {
  return apost("/crypto_live/rank_reward/update", body);
};

type CryptoLiveRankRewardDelete = {
  arr_info_id: number;
};
/**
crypto live 랭킹 보상 그룹 삭제 api
 */
export const deleteCryptoLiveRankRewardDelete = (body: CryptoLiveRankRewardDelete) => {
  return apost("/crypto_live/rank_reward/delete", body);
};

/**
 * crypto live 현재 랭킹 정보 조회
 */
export const getCryptoLiveLankCrypto = () => {
  return aget("/crypto_live/rank/crypto");
};

/**
 * crypto live 이전 차수 랭킹 정보 조회
 */
export const getCryptoLiveLankCryptoHistory = () => {
  return aget("/crypto_live/rank/crypto-history");
};

type CryptoLiveCommentList = {
  day: string;
  page: number;
};
/**
crypto live 댓글 조회
 */
export const postCryptoLiveCommentList = (body: CryptoLiveCommentList) => {
  return apost("/crypto_live/comment/list", body);
};

type CryptoLiveCommentBlock = {
  user_info_id: number;
  comment_blocked: 0 | 1;
  comment_content: string;
};
/**
crypto live 댓글 조회
 */
export const postCryptoLiveCommentBlock = (body: CryptoLiveCommentBlock) => {
  return apost("/crypto_live/comment/block", body);
};
