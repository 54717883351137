/**
 * Notice
 */
export const NOTICE_LIST_COUNT_PER_PAGE = 50;
export const NOTICE_TABLE_HEAD = ["Idx", "제목", "게시 상태", "등록일", "수정일", "게시순서", "삭제"];

/**
 * Banner
 */
export const BANNER_TABLE_HEAD = [
  "Idx",
  "제목",
  "게시 상태",
  "게시 위치",
  "게시 기간",
  "등록일",
  "수정일",
  "게시 순서",
  "삭제",
];
export const BANNER_PAGE_SELECT_DATA = [{ value: "Main", displayValue: "메인" }];

/**
 * Feedback
 */
export const FEEDBACK_TABLE_HEAD = ["Idx", "제목", "피드백 종류", "내용", "등록일", "수정일", "작성자", "확인"];

export const FEEDBACK_PAGE_SELECT_DATA = [
  { value: "title", displayValue: "제목" },
  { value: "content", displayValue: "내용" },
  { value: "email", displayValue: "이메일" },
];

/**
 * Terms
 */
export const TERMS_TABLE_HEAD = ["Idx", "제목", "등록일", "수정일", "삭제"];

/**
 * Withdraw
 */
export const WITHDRAW_TABLE_HEAD = [
  "Idx",
  "아이디",
  "번호",
  "출금 수량",
  "수수료",
  "최종 수령 수량",
  "네트워크",
  "출금 주소",
  "결과",
  "신청 날짜",
  "진행 상태",
  "메모",
];

export const WITHDRAW_REQ_TABLE_HEAD = [
  "코드",
  "신청자",
  "신청수량",
  "수수료",
  "최종수령금액",
  "신청일",
  "메모",
  "진행상태",
  "메뉴",
];

export const WITHDRAW_VERSION_SELECT_DATA = [
  {
    value: "1",
    displayValue: "버전1",
  },
  {
    value: "2",
    displayValue: "버전2",
  },
];

export const WITHDRAW_NETWORK_SELECT_DATA = [
  {
    value: "A",
    displayValue: "모두",
  },
  {
    value: "K",
    displayValue: "클레이튼",
  },
  {
    value: "E",
    displayValue: "이더리움",
  },
];

export const WITHDRAW_STATUS_SELECT_DATA = [
  {
    value: "A",
    displayValue: "모두",
  },
  {
    value: "승인",
    displayValue: "승인",
  },
  {
    value: "신청",
    displayValue: "신청",
  },
  {
    value: "취소",
    displayValue: "취소",
  },
  {
    value: "차단",
    displayValue: "차단",
  },
  {
    value: "기타",
    displayValue: "기타",
  },
];

export const WITHDRAW_CATEGORY_SELECT_DATA = [
  {
    value: "email",
    displayValue: "이메일",
  },
  {
    value: "withdraw_addr",
    displayValue: "지갑주소",
  },
  {
    value: "phone",
    displayValue: "전화번호",
  },
];

export const RANK_TABLE_HEAD = ["순위", "이메일", "USDP"];
export const COMMENT_TABLE_HEAD = ["Idx", "이메일", "댓글 내용", "시간", "댓글 차단"];
