import RegisterRowWrapper from "@/components/table/RegisterRowWrapper";
import { Typography } from "@mui/material";
import React from "react";

type DetailViewProps = {
  detailData: { email: string; address: string; result: string | null; memo: string | null };
};

const DetailView = ({ detailData }: DetailViewProps) => {
  const { email, address, result, memo } = detailData;
  return (
    <div>
      <RegisterRowWrapper title="이메일">
        <Typography sx={{ display: "flex", alignItems: "center", height: "40px" }}>{email}</Typography>
      </RegisterRowWrapper>
      <RegisterRowWrapper title="지갑주소">
        <Typography sx={{ display: "flex", alignItems: "center", height: "40px" }}>{address}</Typography>
      </RegisterRowWrapper>
      <RegisterRowWrapper title="결과">
        <Typography sx={{ display: "flex", alignItems: "center", height: "40px" }}>
          {result ? result : "NULL"}
        </Typography>
      </RegisterRowWrapper>
      <RegisterRowWrapper title="메모">
        <Typography
          sx={{
            maxWidth: "400px",
            minHeight: "40px",
            maxHeight: "350px",
            overflowY: "auto",
            paddingTop: "9px",
            wordWrap: "normal",
            wordBreak: "break-all",
          }}
        >
          {memo ? memo : "NULL"}
        </Typography>
      </RegisterRowWrapper>
    </div>
  );
};

export default DetailView;
