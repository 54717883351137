import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { postManagementBannerList } from "@/api/managementApi";

import Dialog from "@/components/Dialog";
import LayoutContainer from "@/components/LayoutContainer";
import Pagination from "@/components/Pagination";
import Table1 from "@/components/table/Table1";
import BannerDelete from "@/pages/management/banner/BannerDelete";
import ManagementTabBox from "@/pages/management/components/ManagementTabBox";

import { useNavigate } from "react-router-dom";
import { Button, Skeleton } from "@mui/material";

import { BANNER_TABLE_HEAD } from "@/pages/management/managementConst";
import { convertUTCDateToLocalDate, convertYYYYMMDD_HHMMSS } from "@/utils/format";

const Banner = () => {
  const navigate = useNavigate();

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [deleteTitle, setDeleteTitle] = useState("");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [bannerList, setBannerList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    requestManagementBannerList();
  }, [page]);

  const requestManagementBannerList = async () => {
    try {
      const res = await postManagementBannerList({ page });

      const { bannerList, totalCnt } = res.data;

      if (bannerList && totalCnt) {
        setBannerList(res.data.bannerList);
        setTotalCount(res.data.totalCnt);
      } else {
        alert("데이터가 없습니다");
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <LayoutContainer>
      <Dialog open={deleteModal} onClose={() => setDeleteModal(false)} title="등록된 컨텐츠 삭제">
        <BannerDelete
          setModal={setDeleteModal}
          idx={deleteId}
          title={deleteTitle}
          requestManagementBannerList={requestManagementBannerList}
        />
      </Dialog>

      <ManagementTabBox />

      <TopBarContainer>
        <Button variant={"contained"} onClick={() => navigate("/management/banner/register")}>
          + 신규등록
        </Button>
      </TopBarContainer>

      {loading ? (
        <TableContainer>
          {[1, 2, 3, 4, 5].map((_, idx) => (
            <Skeleton key={idx} variant="rectangular" height={60} sx={{ marginBottom: "2px" }} />
          ))}
        </TableContainer>
      ) : (
        <TableContainer>
          {bannerList?.length > 0 && (
            <Table1
              head={BANNER_TABLE_HEAD}
              rows={bannerList.map((data) => {
                const { idx, title, page, start_date, end_date, insert_date, update_date, sort_num, status } = data;

                return [
                  idx,
                  <EditBtn
                    onClick={() =>
                      navigate("/management/banner/edit", {
                        state: { id: idx },
                      })
                    }
                  >
                    {title}
                  </EditBtn>,
                  status,
                  page,
                  `${convertYYYYMMDD_HHMMSS(convertUTCDateToLocalDate(start_date))} ~ ${convertYYYYMMDD_HHMMSS(
                    convertUTCDateToLocalDate(end_date)
                  )}`,
                  convertYYYYMMDD_HHMMSS(convertUTCDateToLocalDate(insert_date)),
                  convertYYYYMMDD_HHMMSS(convertUTCDateToLocalDate(update_date)),
                  sort_num,
                  <Button
                    variant={"contained"}
                    size="small"
                    color="error"
                    onClick={() => {
                      setDeleteId(idx);
                      setDeleteTitle(title);
                      setDeleteModal(true);
                    }}
                  >
                    삭제
                  </Button>,
                ];
              })}
            />
          )}
          <Pagination page={page} selectPage={(page) => setPage(page)} totalPage={Math.ceil(totalCount / 50)} />
        </TableContainer>
      )}
    </LayoutContainer>
  );
};

const TopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TableContainer = styled.div`
  margin-top: 20px;
`;

const EditBtn = styled.div`
  color: #1976d2;
  cursor: pointer;
`;

export default Banner;
